import React from 'react'
import Logo from './Logo'
import NavigationComponent from './NavigationComponent'

export default function HeaderComponent({children}) {
    return (
        <header className="w-full sticky top-0 left-0 z-30">
            <div className="w-full bg-medicina-verde-600" aria-hidden="true">
                <div className="w-full mx-auto flex justify-between items-center" aria-hidden="true">
                    <div className="flex-grow-0">
                        <Logo />
                    </div>
                    {children}
                </div>
            </div>
            <NavigationComponent />
            
        </header>
    )
}
