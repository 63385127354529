import { useRef, useContext } from 'react'
import CartContext from '../../shared/ShoppingCart/CartContext'

export default function ClientInfo({clientOrder = false}) {
    
    const cartContext = useContext(CartContext)

    const name = useRef()
    const lastname = useRef()
    const phone = useRef()
    const email = useRef()
    const rut = useRef()

    return (
        <>
            <div className="block mb-4">
                <label htmlFor="name" className="uppercase text-xs font-black text-medicina-verde-700">Nombre</label>
                <input onInput={ () => cartContext.updateClientInfo({name: name.current.value }) } disabled={clientOrder !== false} type="text" name="name" className={`${clientOrder !== false ? 'cursor-disabled bg-gray-500' : ''} block w-full rounded`} ref={name} />
            </div>

            <div className="block mb-4">
                <label htmlFor="lastname" className="uppercase text-xs font-black text-medicina-verde-700">Apellido</label>
                <input onInput={ () => cartContext.updateClientInfo({lastname: lastname.current.value }) } disabled={clientOrder !== false} type="text" name="lastname" className={`${clientOrder !== false ? 'cursor-disabled bg-gray-500' : ''} block w-full rounded`} ref={lastname} />
            </div>

            <div className="block mb-4">
                <label htmlFor="phone" className="uppercase text-xs font-black text-medicina-verde-700">Teléfono de contacto</label>
                <input onInput={ () => cartContext.updateClientInfo({phone: phone.current.value }) } disabled={clientOrder !== false} type="text" name="phone" className={`${clientOrder !== false ? 'cursor-disabled bg-gray-500' : ''} block w-full rounded`} ref={phone} />
            </div>

            <div className="block mb-4">
                <label htmlFor="email" className="uppercase text-xs font-black text-medicina-verde-700">Correo electrónico</label>
                <input onInput={ () => cartContext.updateClientInfo({email: email.current.value }) } disabled={clientOrder !== false} type="text" name="email" className={`${clientOrder !== false ? 'cursor-disabled bg-gray-500' : ''} block w-full rounded`} ref={email} />
            </div>

            <div className="block mb-4">
                <label htmlFor="rut" className="uppercase text-xs font-black text-medicina-verde-700">Rut <span className="font-medium">(sólo para validar la entrega)</span></label>
                <input onInput={ () => cartContext.updateClientInfo({rut: rut.current.value }) } disabled={clientOrder !== false} type="text" name="rut" className={`${clientOrder !== false ? 'cursor-disabled bg-gray-500' : ''} block w-full rounded`} ref={rut} />
            </div>
        </>
    )
}
