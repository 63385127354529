import {useEffect, useState} from 'react'

export default function GalleryComponent({attachment,parentAttachment}) {

    const [image, setImage] = useState("https://cdn.lamedicina.cl/static/img/noimage.png");
    const attachmentObject = attachment || parentAttachment;

    useEffect(() => {
        if (!attachmentObject) return setImage('https://cdn.lamedicina.cl/static/img/noimage.png')
        const url = process.env.REACT_APP_API + '/attachment/' + attachmentObject.attachment_id
        fetch(url)
            .then(res => res.json())
            .then(res => {
                setImage("https://cdn.lamedicina.cl/"+res.filename)
            })
            .catch(err => setImage('https://cdn.lamedicina.cl/static/img/noimage.png'))
    }, [attachmentObject])

    return (
        <img className="w-full h-auto rounded mb-2" src={ image ?? "https://cdn.lamedicina.cl/static/img/noimage.png" } alt="La Medicina" />
    )
}