import React, { useContext, useEffect, useState } from 'react'
import CartContext from '../../shared/ShoppingCart/CartContext'
import { Transition } from '@headlessui/react'
import CartComponent from './CartComponent'

export default function CartButtonComponent() {

    const cartContext = useContext(CartContext)
    const [animate, setAnimate] = useState(false)
    const [cartOpen, setCartOpen] = useState(false)

    useEffect(() => {
        if (!cartOpen && cartContext.state.showCart) {
            setCartOpen(true)
        }
        if (cartOpen && !cartContext.state.showCart) {
            setAnimate(true)
            setTimeout(function () {
                setAnimate(false)
            }, 1000)
            setCartOpen(false)
        }

    }, [cartContext.state.showCart, cartOpen])

    return (
        <>
            <div className="h-full relative select-none  border-l px-4 border-medicina-verde-700 hover:bg-medicina-verde-700 hover:text-medicina-verde-500 transition-colors" onClick={() => cartContext.toggleCart()}>
                <div className="h-full transition justify-center cursor-pointer flex py-3 px-2">
                    <div className="flex items-center text-medicina-cafe-500">
                        <div className={`${animate ? 'carrito-bloom' : ''} carrito transform scale-75`} style={{ width: "41px", height: "41px" }}></div>
                    </div>
                    <div className="hidden md:inline-block text-xs text-medicina-verde-50 self-center px-2 font-bold">
                        Ver <br />
                        Carrito
                    </div>
                    <div className="bg-yellow-500 text-xs text-white flex items-center justify-center rounded-full w-6 h-6 shadow absolute top-2 right-4 font-black">{cartContext.state.totalProductos}</div>
                </div >
            </div>
            <Transition show={cartContext.state.showCart}
                enter="transition-opacity duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="z-20"
            >
                <CartComponent />
            </Transition>
        </>
    )
}
