import React from 'react'

export default function OutOfStockAlert({ products = false }) {
    return (
        products !== false &&
        <div className="w-full mx-auto rounded px-4 py-6 bg-yellow-200 text-yellow-800 shadow text-sm my-6 ">
            <h1 className="font-black uppercase text-md">Hemos modificado algunos productos en tu orden</h1>
            <p>Algunos de los productos en tu carrito se encontraban sin stock (ya volverán 😅) por lo que actualizamos las cantidades de los productos en tu compra.
                Si deseas eliminar del todo el item de tu carrito, modifica tu carrito de compras.</p>

            <div>
                <h1 className="font-bold mt-3">Estos productos se quitaron de tu pedido:</h1>
                <div className="w-full">
                    {
                        products.length > 0 &&
                            products.map(product => {
                                return (
                                    <div className="p-3 border-b border-yellow-300" key={"oos-"+product.id}>
                                        {product.units} x {product.product.name}
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
        </div>
    )
}
