import React from 'react'

/**
 * Pantalla de carga simple
 * con animación basada en sprites
 * y entrada/salida con fade **/

export default function LoaderComponent({ loadingMessage }) {
    return (
        <div className="w-screen h-screen bg-medicina-verde-900 bg-opacity-70 backdrop-filter backdrop-blur-lg fixed top-0 left-0 z-50">
            <div className="flex justify-center flex-col items-center mt-20">
                <div className="transform scale-50 m-0 p-0" aria-hidden="true">
                    <img className="animate-pulse" src="https://cdn.lamedicina.cl/static/img/LOGOMEDICINA.png" alt="Cargando..." />
                </div>
                <h1 className="text-xl -mt-14 md:-mt-28 text-medicina-verde-300 medicina-font">{loadingMessage}</h1>
            </div>
        </div>
    )
}