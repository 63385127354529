import { ChevronDownIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'

export default function CategoryReveal() {

    const [state, setState] = useState(false)
    const [midlevel, setMidlevel] = useState(false)
    const [topLevel, setTopLevel] = useState(false)

    useEffect(() => {
        if (!state) {
            const url = `${process.env.REACT_APP_API}/categories`
            return fetch(url)
                .then(res => res.json())
                .then(data => {
                    console.log(data)
                    setState(data)
                }).then(() => true)
        }
    }, [state])

    const setCurrent = (topLevel) => {
        setTopLevel(topLevel)
        setMidlevel(topLevel.children)
    }

    return (
        <div className="flex border-t">
            <div className="bg-gray-300">
                <ul className="divide-y text-medicina-cafe-600">
                    {state && state.map(topLevel => {
                        return (
                            <li onClick={() => setCurrent(topLevel)} key={"ctegoria-top-level-" + topLevel.id} className="px-3 py-2 space-x-3 md:space-x-20 flex justify-between items-center">
                                <h1 className="text-xs md:text-md font-semibold ">{topLevel.name}</h1>
                                <ChevronDownIcon className="w-4 h-4 -rotate-90 transform" />
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="overflow-auto h-screen md:h-2/4 w-full flex flex-row flex-wrap items-start justify-start  pb-20">
                {midlevel &&
                    <div className="md:h-2/4 w-full flex flex-row flex-wrap items-start justify-start pb-20">
                        <div className="text-right w-full px-4 py-2 bg-gray-100 mb-2 text-xs uppercase font-semibold text-medicina-cafe-500">
                            <a href={`/categorias/productos?lamedicina_ecommerce_PROD_products[refinementList][hirearchical_categories.level0][0]=${topLevel.name.toLowerCase()}`}>Ver todos en {topLevel.name}</a>
                        </div>

                        {midlevel.map(midLevel => {
                            return (
                                <div className="text-sm px-4 py-2 font-bold w-36 h-auto" key={"categoria-mid-level-" + midLevel.id}>
                                    <a href={`/categorias/productos?lamedicina_ecommerce_PROD_products[refinementList][hirearchical_categories.level1][0]=${topLevel.name.toLowerCase()} > ${midLevel.name.toLowerCase()}`} >
                                        <h1 className="mb-2 text-medicina-verde-500">{midLevel.name}</h1>
                                    </a>
                                    <div className="pl-2">
                                        {midLevel.children.map(lastLevel => {
                                            return (
                                                <div className="text-xs font-normal text-gray-600 hover:text-medicina-verde-500 hover:underline" key={"ctegoria-last-level-" + lastLevel.id}>
                                                    {/* <a href={`/categorias/${topLevel.name.toLowerCase()}/${midLevel.name}/${lastLevel.name}`} >{lastLevel.name}</a> */}
                                                    <a href={`/categorias/productos?lamedicina_ecommerce_PROD_products[refinementList][hirearchical_categories.level0][0]=${topLevel.name}&lamedicina_ecommerce_PROD_products[hirearchical_categories.level1][0]=${midLevel.name.toLowerCase() + " > " + midLevel.name.toLowerCase()}&lamedicina_ecommerce_PROD_products[refinementList][hirearchical_categories.level2][0]=${topLevel.name.toLowerCase()} > ${midLevel.name.toLowerCase()} > ${lastLevel.name.toLowerCase()}`} >
                                                        {lastLevel.name}</a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                }

            </div>
        </div>
    )
}
