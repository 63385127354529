import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-hooks";
import { useState } from "react";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import CartButtonComponent from "../CartComponent/CartButtonComponent";
import CategoryButton from "../CategoryMenuComponent/CategoryButton";
import SearchComponent from "../SearchComponent/SearchComponent";
import {
  SearchCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import AgeGateComponent from "../AgeGateComponent/AgeGateComponent";
import { Transition } from "@headlessui/react";
import MobileSearchComponent from "./MobileSearchComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function MainLayout({ children }) {
  const [showSearch, setShowSearch] = useState(false);

  const [ageGate, setAgeGate] = useState(
    window.localStorage.getItem("mayor") ? true : false
  );

  // if the pathname is /ofertas routing is false
  const routing = window.location.pathname === "/categorias/productos" ? true : false;

  
  const indexName = "lamedicina_ecommerce_PROD_products";
  const searchClient = algoliasearch(
    "1AW7QK3708",
    "0078f54885ff4a4a96ed89a721d1d562"
  );

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName} routing={routing} suppressExperimentalWarning={true}>

      <div className="w-full flex flex-col bg-gray-200 min-h-full">
        <HeaderComponent>
          <div className="w-full flex items-stretch">

            <div className="flex-grow-0 flex space-x-2 self-stretch">
              <CategoryButton />
            </div>

            <div className="hidden md:flex flex-grow w-full px-4 self-stretch">
              <div className="w-full flex items-center">
                <SearchComponent showSearch={showSearch} />
              </div>
            </div>

            <div className="flex-grow-0 flex space-x-2">
              <CartButtonComponent />
            </div>
          </div>
        </HeaderComponent>

        <div className="flex-grow">{children}</div>

        <div className="justify-self-end flex-shrink-0 z-10 relative">
          <FooterComponent />
        </div>

        <div className="md:hidden fixed bottom-3 px-2 z-20 w-full">
          <div className="w-full h-full relative">

            <div className="animate-pulse z-50 absolute right-0 -top-10" onClick={() => setShowSearch(!showSearch)} >
              <div className="bg-white rounded-full border shadow">
                {!showSearch ? (
                  <SearchCircleIcon className="w-10 h-10 text-yellow-500" />
                ) : (
                  <XCircleIcon className="w-10 h-10 text-red-500" />
                )}
              </div>
            </div>

          </div>

          <Transition
            show={showSearch}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <MobileSearchComponent />
          </Transition>

        </div>

        {!ageGate && <AgeGateComponent setAgeGate={setAgeGate} />}
        <ToastContainer />

      </div>
    </InstantSearch>

  );
}
