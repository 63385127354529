import { useState, useEffect } from 'react'

export default function DescriptionComponent({ childDescription, productId, parentId }) {

    const [parentDescription, setParentDescription] = useState(false)

    useEffect(() => {
        if ( parentId ) {
            const url = process.env.REACT_APP_API + '/products/' + parentId
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    setParentDescription(data.data.description)
                })
        }
    }, [parentId])

    return (
        <div className="text-md">
            {parentDescription &&
                parentDescription !== 'Descripción genérica' &&
                <div className="text-gray-700 text-justify mb-6" dangerouslySetInnerHTML={{
                    __html: parentDescription
                }} />
            }
            {childDescription &&
                childDescription !== 'Descripción genérica' &&
                <div className="text-gray-700 text-justify" dangerouslySetInnerHTML={{
                    __html: childDescription
                }} />
            }
        </div>
    )
}
