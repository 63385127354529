import { useEffect, useState } from 'react'

export default function StockComponent({ productId, pack }) {

    /**
     * TODO:
     *  incluir contexto global para notificar cuando mostrar o no los botones
     * de compra y de agregar al carro. Usar botones deshabilitados cuando
     * no stock === 0
     */
    const [stock, setStock] = useState(false)
    const [total, setTotal] = useState(false)

    useEffect(() => {
        if (stock === false) {
            const url = process.env.REACT_APP_API + '/products/' + productId + '/stock'
            return fetch(url)
                .then(res => res.json())
                .then(data => setStock(data))
        }
    }, [stock, setStock, productId])

    useEffect(() => {
        if (stock !== false && total === false) {
            let count = 0
            stock.map(store => count += store.units)
            setTotal(count)
        }
    }, [stock, setTotal, total])

    return (

        <div>
            { (total === 0) &&
                <>
                    <div className="w-full block bg-red-300 text-red-900 rounded p-4 text-sm">
                        Este producto se encuentra actualmente <strong>sin stock</strong>.
                    </div>
                </>
            }

            {/* { (total !== 0) &&
                <>
                    <span className="block text-sm mb-4 text-gray-400 uppercase font-semibold">Stock</span>
                    <div className="text-medicina-verde-800 grid grid-cols-2 grid-rows-1 gap-3">
                        {stock && stock.map(store => <StoreStockButton units={store.units} storeId={store.store_id} key={store.id} />)}
                    </div>
                </>
            } */}
        </div>
    )
}
