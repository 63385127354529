import React, { useContext, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import CartContext from '../../shared/ShoppingCart/CartContext'
import SkeletonText from '../skeletonText/SkeletonText'

export default function AddToCartComponent({ productId, color = "green", setGrayFilter, pack = false, isBundle = false, bsaleId }) {

    const cartContext = useContext(CartContext)
    const [stock, setStock] = useState(false)
    const [packLength, setPackLength] = useState(0)
    const [packItems, setPackItems] = useState([])

    const style = color === 'yellow' ? 'bg-yellow-500 hover:bg-yellow-600 text-medicina-verde-50' : 'bg-medicina-verde-500 hover:bg-medicina-verde-600 text-medicina-verde-50';
    
    useEffect(() => {
        console.log("stock", stock);
        console.log("pack", pack);

        if (stock === false) {
            let url = 'https://api.lamedicina.cl/api/v2/product/stock/' + bsaleId
            if (pack)  url = 'https://api.lamedicina.cl/api/v2/product/stock/' + productId +'?pack=true'
            return fetch(url)
                .then(res => res.json())
                .then(data => {
                   setStock(data.stock ?? 0) 
                })
                .catch(err => console.log(err))
        }
    }, [productId, stock])

    useEffect(() => {
        if (setGrayFilter !== undefined) {
            if (stock > 0) {
                setGrayFilter(true)
            } else {
                setGrayFilter(false)
            }
        }
    }, [stock, setGrayFilter])

    function add(p, c) {
        cartContext.addToCart(p, c)
    }

    return (
        <div>
            {stock === false && <SkeletonText />}
            {stock > 0 &&
                <button onClick={debounce(() => add(productId, 1))} className={`block w-full text-center py-1 rounded transition-colors ease-in-out ${style}`}>Agregar al carrito</button>
            }
            {stock === 0 &&
                <button className="block w-full text-center py-1 rounded bg-medicina-verde-300  text-medicina-verde-100 transition-colors ease-in-out cursor-not-allowed">Sin Stock</button>
            }
        </div>
    )
}
