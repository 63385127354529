import React, { useRef, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { MenuIcon } from '@heroicons/react/outline'
import CategoryMenu from './CategoryMenu'

export default function CategoryButton() {
    const [open, setOpen] = useState(false)

    const ref = useRef()

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [open])

    return (
        <div className="select-none ml-2 border-l border-r  border-medicina-verde-700 flex">
            <div onClick={() => setOpen(true)}
                className="transition justify-center cursor-pointer flex p-3 hover:bg-medicina-verde-700 hover:text-medicina-verde-500 px-4">

                <div className="flex items-center text-medicina-cafe-500">
                    <MenuIcon className="w-7 h-7 " />
                </div>

                <div className="text-xs font-bold text-medicina-verde-50 self-center px-2 md:inline-block hidden">
                    Ver <br />
                    Categorías
                </div>
            </div >

            <Transition show={open}
                enter="transition-opacity duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed z-50 w-screen h-screen bg-medicina-verde-800 top-0 left-0 bg-opacity-80 backdrop-filter backdrop-blur-lg">
                <div className="w-full md:w-10/12 lg:w-8/12 2xl:w-5/12 mx-auto pb-2 md:pt-2 block" ref={ref} >
                        <CategoryMenu setOpen={setOpen} />
                    </div>
                </div>
            </Transition>
        </div>
    )
}
