import { useEffect, useState } from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import Categories from './Categories'
import Hero from './Hero'
import Recent from './Recent'
import PostComponent from '../../components/BlogComponent/PostComponent'
import InstagramComponent from '../../components/InstagramComponent/InstagramComponent'

export default function NewHome() {

    const [posts, setPosts] = useState(false)

    useEffect(() => {
        if (!posts) {
            const url = "https://blog.lamedicina.cl/wp-json/wp/v2/posts"
            return fetch(url)
                .then(res => res.json())
                .then(data => setPosts(data))
        }
    }, [posts, setPosts])

    return (
        <MainLayout>
            <Hero />
            
            <div className='flex h-full w-full p-4 sm:w-11/12 xl:w-10/12 mx-auto mb-10 mt-10 gap-x-9 flex-col md:flex-row'>
                <section className='w-full'>
                    <div className="w-full text-left pt-6 lg:pt-0 mb-6 relative">
                        <div className="pl-2">
                            <h1 className="text-2xl md:text-4xl text-medicina-cafe-500 font-black leading-none medicina-font">Productos en oferta</h1>
                            <h6 className="md:text-xl font-light italic text-medicina-verde-500 ">Grandes descuentos<br className='md:hidden' /> por tiempo limitado!</h6>
                        </div>

                        <div className='absolute bottom-0 right-2'>
                            <a href="/ofertas" className='md:text-xl font-light italic text-medicina-verde-500 hover:text-medicina-verde-800'>Ver más ofertas</a>
                        </div>
                    </div>

                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                        <Recent origin={"sale"} />
                    </div>
                </section>
            </div>

            <div className='flex h-full w-full p-4 sm:w-11/12 xl:w-10/12 mx-auto mb-10 mt-10 gap-x-9 flex-col md:flex-row'>
                <section className='w-full'>
                    <div className="w-full text-left pt-6 lg:pt-0 mb-6 relative">
                        <div className="pl-2">
                            <h1 className="text-2xl md:text-4xl text-medicina-cafe-500 font-black leading-none medicina-font">Promociones imperdibles</h1>
                            <h6 className="md:text-xl font-light italic text-medicina-verde-500">Pagas uno y llevas dos.. o más!</h6>
                        </div>

                        <div className='absolute bottom-0 right-2'>
                            <a href="/promos" className='md:text-xl font-bold md:font-light italic text-medicina-verde-500 hover:text-medicina-verde-800'>Ver más promos</a>
                        </div>
                    </div>

                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                        <Recent origin={"packs"} />
                    </div>
                </section>
            </div>

            <section className='mb-20'>
                <div className='w-full block'>
                    <div className='flex flex-row gap-8 w-9/12 mx-auto'>
                        <Categories />
                    </div>
                </div>
            </section>

            <div className='flex h-full w-full p-4 sm:w-11/12 xl:w-10/12 mx-auto mb-10 mt-24 gap-x-3 flex-col md:flex-row'>

                <div className='w-full flex-grow'>
                    <section className='mb-12'>

                        <div className="w-full text-left pt-6 lg:pt-0 mb-6 flex items-center justify-between pr-6">
                            <div className="pl-2">
                                <h1 className="text-xl md:text-4xl text-medicina-cafe-500 font-black leading-none medicina-font">Los Productos Más Recientes</h1>
                                <h6 className="md:text-xl font-light italic text-medicina-verde-500">Lo más nuevo de nuestra tienda</h6>
                            </div>

                            <div className='text-right'>
                                <a href="/categorias/productos" className='md:text-xl font-light italic text-medicina-verde-500 hover:text-medicina-verde-800'>Ver más productos</a>
                            </div>
                        </div>

                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                            <Recent origin="latest" />
                        </div>
                    </section>

                </div>

                {/* <div className='w-full lg:w-3/12'>
                    <BlogComponent />
                    <Instagram />
                </div> */}

            </div>

           




            <section className='flex h-full w-full p-4 sm:w-11/12 xl:w-10/12 mx-auto mb-10 mt-10 gap-x-3 flex-col md:flex-row' id="articulos">
                <div className="mt-10 block md:flex md:space-x-12">

                    <div className="w-full md:w-2/3">

                        <div className="mx-auto pt">
                            <div className="mb-8">
                                <h1 className="text-2xl md:text-4xl te∆xt-medicina-cafe-500 font-black leading-none medicina-font text-medicina-cafe-500">Blog y Noticias</h1>
                                <h6 className="md:text-xl font-light italic text-medicina-verde-500">Novedades, tips y actualidad.</h6>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-3">
                            {!posts && <div className="w-full h-full flex justify-center items-center"><div className="germinando transform scale-50"></div></div>}
                            {posts &&
                                posts.map(post => {
                                    return <PostComponent link={post.link} key={"post-blog-" + post.id} title={post.title.rendered} media={post.featured_media} excerpt={post.excerpt.rendered.replace(/(<([^>]+)>)/ig, '')} date={post.date_gmt} />
                                })
                            }
                        </div>

                    </div>

                    <div className="w-full md:w-1/3">
                        <InstagramComponent />
                    </div>
                </div>
            </section>

            
        </MainLayout>
    )
}
