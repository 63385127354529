import React from 'react'
import SkeletonImage from '../skeletonImage/SkeletonImage'
import SkeletonText from '../skeletonText/SkeletonText'

export default function ProductSkeleton() {

    return (
        <div className="shadow-sm bg-white rounded w-full h-full flex flex-col justify-start items-center pb-2" style={{ maxHeight: '500px' }}>
            
            <div className="h-60 pt-3 px-2 w-full flex-shrink-0-0">
                <SkeletonImage />
            </div>
            
            <div className="px-3 pt-3 text-gray-800 flex flex-col w-full flex-grow">
                <SkeletonText />
                <SkeletonText />
                <div className="mt-6 h-full justify-end flex flex-col pb-4">
                    <SkeletonText />
                    <SkeletonText />
                </div>
            </div>
        </div>

    )

}
