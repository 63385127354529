import SearchComponent from "../SearchComponent/SearchComponent";
import { SearchIcon } from "@heroicons/react/solid";
import { useRef } from "react";

export default function MobileSearchComponent() {

  const searchInputRef = useRef()

  const handleClick = () => {
    //categorias/productos?lamedicina_ecommerce_PROD_products%5Bquery%5D=bong
    window.location.href = `/categorias/productos?lamedicina_ecommerce_PROD_products[query]=${encodeURIComponent(searchInputRef.current.value)}`
  }

  return (
    <div className="h-screen w-screen fixed left-0 top-20 -mt-4 bg-white z-40 text-2xl flex items-stretch border-green-500 border">
      <div className="w-full h-full relative flex items-stretch flex-col">
        <div className="px-2 mt-1">
          <input
            ref={searchInputRef}
            type="text"
            name="search"
            id="search_mobile"
            className="mb-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-medicina-verde-500 focus:ring-medicina-verde-500 sm:text-sm"
            placeholder="bong, pipa, papel, sustrato..."
          />

          <button
            type="button"
            onClick={handleClick}
            className="w-full justify-center inline-flex items-center rounded-md border border-transparent bg-medicina-verde-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-medicina-verde-700 focus:outline-none focus:ring-2 focus:ring-medicina-verde-500 focus:ring-offset-2"
          >
            <SearchIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            Buscar
          </button>

        </div>
        <div className="w-full h-full flex-grow p-2 overflow-y-auto pb-2"></div>
      </div>
    </div>
  );
}
