import { SearchIcon } from "@heroicons/react/solid";
import { useEffect, useState, useRef } from "react";
import { useSearchBox } from "react-instantsearch-hooks";

export function SearchBox(props) {
  const { query, refine} = useSearchBox(props);
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.focus) inputRef.current.focus();

  }, [props.focus]);

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    window.location.href = `/categorias/productos?lamedicina_ecommerce_PROD_products[query]=${query}`;
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }

  function handleReset(event) {
    event.preventDefault();
    event.stopPropagation();

    setInputValue("");

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  // Track when the value coming from the React state changes to synchronize
  // it with InstantSearch.
  useEffect(() => {
    if (query !== inputValue) {
      refine(inputValue);
    }
  }, [inputValue, refine, query]);

  // Track when the InstantSearch query changes to synchronize it with
  // the React state.
  useEffect(() => {
    // Bypass the state update if the input is focused to avoid concurrent
    // updates when typing.
    if (document.activeElement !== inputRef.current && query !== inputValue) {
      setInputValue(query);
      props.setQuery(query);
    }
  }, [query, inputValue, props]);

  return (
    <div className="ais-SearchBox relative">
      <form
        action="/categorias/productos"
        className="ais-SearchBox-form"
        noValidate
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        <input
          ref={inputRef}
          className="w-full rounded border-none shadow-sm px-3"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={props.placeholder}
          spellCheck={false}
          maxLength={512}
          type="search"
          value={inputValue}
          onChange={(event) => setInputValue(event.currentTarget.value)}
        />
        <button className="absolute right-6 bottom-2"><SearchIcon className="w-6 h-6" /></button>
      </form>
    </div>
  );
}
