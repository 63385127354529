import { useEffect } from 'react';
import { useHits } from 'react-instantsearch-hooks';

export function Hits({ hitComponent: Hit, mobile = false, searchbox = true }) {
  const { hits, results } = useHits();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [hits])

  return (
    !searchbox ? (
      <>
        <div className="w-full md:flex justify-between items-center px-4 pt-4 sticky md:top-24  mb-1 md:mb-0">
          <div className="hidden md:inline-block">
            {/* <BreadCrumbs data={currCat.full_category_list} /> */}
          </div>
          <div className="rounded-3xl bg-gray-300 shadow-inner text-gray-600 px-5 py-1 medicina-font text-center md:text-right md:mt-0 mt-2 bg-opacity-50 z-30 backdrop-filter backdrop-blur">
            {results.nbHits + " resultados"}
          </div>
        </div>
        <div className="min-h-screen grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 p-4 border-green-300 border-dashed">
          {hits.map((hit) => <Hit key={hit.objectID} hit={hit} mobile={mobile} />)}
        </div>
      </>
    ) : (
      hits.map((hit) => <Hit key={hit.objectID} hit={hit} mobile={mobile} />)
    )
  )
}