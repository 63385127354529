import React from 'react'

export default function AgeGateComponent({setAgeGate}) {
    const soyMayor = () => {
        window.localStorage.setItem('mayor', "true");
        setAgeGate(true)
    }
    return (
        <div className="z-50 flex items-start justify-center fixed top-0 left-0 w-full h-screen bg-medicina-verde-900 bg-opacity-75 backdrop-filter backdrop-blur">
            <div className="bg-white rounded shadow mt-20 mx-auto p-4 md:p-8 w-11/12 md:w-5/12">
                
                <img src="https://cdn.lamedicina.cl/static/img/medicina.svg" className="md:w-1/2 h-auto mx-auto pb-6 md:pb-10" alt="Aviso legal" />
                
                <div className="text-center md:text-xl md:pb-8 pb-6">
                    La venta de artículos de coleccionismo , como nuestras semillas de cáñamo, 
                    son vendidas con fines de conservación genética exclusivamente, 
                    como semillas de colección y souvenir de venta exclusiva para mayores de 18 años.
                </div>

                <div className="w-full flex justify-center space-x-2 ">
                    <button onClick={soyMayor} className="w-full text-center py-4 medicina-font text-3xl text-red-600">Entrar</button>
                    <a href="//google.com" className="w-full text-center py-4 medicina-font text-3xl text-blue-600">Salir</a>
                </div>
            </div>
        </div>
    )
}
