import { useState, useEffect, useRef, useContext } from 'react'
import regionData from '../../assets/regiones'
import CartContext from '../../shared/ShoppingCart/CartContext'

export default function LocationComponent({setCity}) {

    const cartContext = useContext(CartContext)

    let regionInput = useRef(null)
    let ciudad = useRef(null)
    let address = useRef(null)

    const [region, setRegion] = useState(false)
    const [comunas, setComunas] = useState([])
    const [selectedCity, setSelectedCity] = useState(false)
    const [selectedAddress, setSelectedAddress] = useState(false)

    useEffect(() => {
        if (region && region !== "0") {
            cartContext.updateClientInfo({ region: region })
            setComunas(regionData.filter(item => item.region === region)[0].comunas)
        } else {
            setComunas([])
        }
    }, [region])

    useEffect(() => {
        if (region && ciudad !== null) {
            console.log(ciudad.current.value)
            setCity(ciudad.current.value)
            cartContext.updateClientInfo({ ciudad: ciudad.current.value })
        }
    }, [selectedCity, region])

    useEffect(() => {
        if (address !== null) {
            console.log(address.current.value)
            cartContext.updateClientInfo({ address: address.current.value })
        }
    }, [selectedAddress])


    return (
        <>
            <div className="block mb-4">
                <label htmlFor="nombre" className="uppercase text-xs font-black text-medicina-verde-700">Región</label>
                <select className="w-full" ref={regionInput} onChange={() => setRegion(regionInput.current.value)}>
                    <option value="0">Selecciona una región</option>

                    {regionData.filter(region => region.region).map(region => (
                        <option value={region.region} key={region.region}>{region.region}</option>
                    ))}
                </select>
            </div>

            <div className="block mb-4">
                <label htmlFor="nombre" className="uppercase text-xs font-black text-medicina-verde-700">Ciudad</label>
                <select className="w-full" disabled={comunas.length < 1} ref={ciudad} onChange={() => setSelectedCity(ciudad.current.value)}>
                    <option value="0">Selecciona una ciudad</option>
                    {comunas.length > 0 && comunas.map(comuna => (
                        <option value={comuna} key={comuna}>{comuna}</option>
                    ))}
                </select>
            </div>

            <div className="block mb-4">
                <label htmlFor="direccion" className="uppercase text-xs font-black text-medicina-verde-700">Dirección de entrega</label>
                <textarea type="text" name="direccion" className="block w-full rounded" ref={address} onKeyUp={() => setSelectedAddress(address.current.value)} />
            </div>
        </>
    )
}
