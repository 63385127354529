import { useEffect, useRef, useState } from "react";
import { SearchBox } from "../../pages/AlgoliaSearch/SearchBox";
import Hit from "./Hit";
import { Hits } from "../../pages/AlgoliaSearch/Hits";
import { Transition } from "@headlessui/react";

export default function SearchComponent({mobile = false}) {
  const [showResults, setShowResults] = useState(false);
  const [query, setQuery] = useState("");

  const ref = useRef();
  const resultsRef = useRef();

  useEffect(() => {
    if (resultsRef.current) resultsRef.current.scrollTop = 0;
  }, [query]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showResults]);

  return (
    <div className="relative w-full h-full pt-3">
        <div className="absolute w-full " onClick={() => setShowResults(true)} ref={ref}>
          <SearchBox
            placeholder="Semillas, bongs, encendedor..."
            focus={showResults}
            setQuery={setQuery}
          />
          <Transition show={showResults}>
            {!mobile && (
              <div
                className="absolute w-full overflow-auto max-h-80 -mt-1 shadow-2xl"
                ref={resultsRef}
              >
                <Hits hitComponent={Hit} />
                {/* <div className="text-sm uppercase font-semibold text-right px-3 py-2">Ver todos los resultados</div> */}
              </div>
            )}
          </Transition>
        </div>
    </div>
  );
}
