import React, { useEffect, useRef, useState, useContext } from 'react'
import { TrashIcon } from '@heroicons/react/solid'
import PriceComponent from '../PriceComponent/PriceComponent'
import CartContext from '../../shared/ShoppingCart/CartContext'
import { Transition } from '@headlessui/react'

export default function CartItemComponent({ product, quantity = false, index, setTtotal, attachment }) {
    
    const cantidad = useRef()
    const [qtt, setQtt] = useState(false)
    const cartContext = useContext(CartContext)
    const attachmentObject = attachment;
    const [image, setImage] = useState(false);

    useEffect(() => {
        if (!attachmentObject) return setImage('https://cdn.lamedicina.cl/static/img/noimage.png')
        const url = process.env.REACT_APP_API + '/attachment/' + attachmentObject.attachment_id
        fetch(url)
            .then(res => res.json())
            .then(res => {
                setImage("https://cdn.lamedicina.cl/" + res.filename)
            })
            .catch(err => setImage('https://cdn.lamedicina.cl/static/img/noimage.png'))
    }, [attachmentObject])


    useEffect(() => {
        quantity.map(item => (item.productId === product.id) && setQtt({ units: item.quantity }))
    }, [quantity,product.id])

    const removeItem = () => {
        cartContext.removeFromCart(index)
        setTtotal(Math.random())
    }

    const sumItem = () => {
        cartContext.sumItem(index, cantidad.current.value)
        setTtotal(Math.random())
    }

    return (
        <Transition show={index !== -1}
            enter="transform transition duration-[400ms]"
            leave="transform duration-200 transition ease-in-out"
            leaveFrom="opacity-100 rotate-0 scale-100 "
            leaveTo="opacity-0 scale-95 ">
            <div className="flex items-center space-x-2">
                <div className="w-24 h-24 flex-grow-0 flex-shrink-0 p-2">
                    <img className=" border rounded-lg" src={image} alt={product.name} />
                </div>
                <div className="flex-grow">
                    <h1 className="text-sm text-gray-800 font-bold">{product.name}</h1>
                    {product.price && <PriceComponent bsaleId={product.bsale_id} price={product.price[0].price} />}
                </div>
                <div>
                    <input ref={cantidad} type="number" min="0" className="w-20 rounded" defaultValue={qtt.units} onChange={() => sumItem()} />
                </div>
                <div className="flex space-x-1">
                    <button onClick={() => { removeItem() }} className="flex items-center p-3 text-xs bg-gray-300 hover:bg-red-700 transition-colors text-white rounded"><TrashIcon className="w-5 inline-block" /></button>
                </div>
            </div>
        </Transition>
    )
}
