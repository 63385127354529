import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'

export default function ContactPage() {
    return (
        <MainLayout>
            <div className="w-11/12 mx-auto pt">
                <div className="pl-2 pt-10">
                    <h1 className="text-4xl text-medicina-cafe-500 font-black leading-none medicina-font">Cómunicate con nosotros!</h1>
                    {/* <h6 className="text-xl font-light italic text-medicina-verde-500">Todos los productos de tus marcas favoritas.</h6> */}
                </div>

                <div className="px-3 text-left mt-6 text-medicina-verde-600">
                    Rellena el siguiente formulario para comunicarte con nostros. Asegurate de incluir algun metodo de contacto y no olvides que puedes ubicarnos también en el whatsapp!.
                </div>

                <div className="px-3 mt-10">
                    <div className="md:pr-14">
                        <div className="block mb-4">
                            <label htmlFor="nombre" className="uppercase text-xs font-black text-medicina-verde-700">Nombre y Apellido</label>
                            <input type="text" name="nombre" className="block w-full rounded" />
                        </div>

                        <div className="block mb-4">
                            <label htmlFor="nombre" className="uppercase text-xs font-black text-medicina-verde-700">Teléfono de contacto</label>
                            <input type="text" name="nombre" className="block w-full rounded" />
                        </div>

                        <div className="block mb-4">
                            <label htmlFor="nombre" className="uppercase text-xs font-black text-medicina-verde-700">Correo electrónico</label>
                            <input type="text" name="nombre" className="block w-full rounded" />
                        </div>

                        <div className="block mb-4">
                            <label htmlFor="nombre" className="uppercase text-xs font-black text-medicina-verde-700">Mensaje</label>
                            <textarea type="text" name="nombre" className="block w-full rounded" />
                        </div>

                        <div className="block mb-4">
                            <button className="px-8 py-2 bg-medicina-verde-600 text-white rounded">Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}
