import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'

export default function LocationPage() {
    return (
        <MainLayout>
            <div className="w-11/12 flex mx-auto mb-40">

                <div className="w-1/3 py-10 pr-10 medicina-font">
                    <h1 className="text-5xl font-black text-medicina-verde-500 mb-40">Bernardo Ohiggins #276</h1>
                    <p className="text-3xl font-bold mb-2">Teléfono: +56 9 8955 0128</p>
                    <p className="text-3xl font-bold">Correo: soporte@lamedicina.cl</p>
                </div>

                <div className="w-2/3 pt-4">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2392.4110690717944!2d-70.89895098385466!3d-53.15666327993964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdb26381a724f6af%3A0xe6941c017d499cd6!2sLibertador%20Bernardo%20O&#39;Higgins%20276%2C%20Punta%20Arenas%2C%20Magallanes%20y%20la%20Ant%C3%A1rtica%20Chilena!5e0!3m2!1ses!2scl!4v1657660481140!5m2!1ses!2scl" width="600" height="500" frameborder="0" style={{ border: 0 }} title="Mapa-LaMedicina" allowfullscreen="" aria-hidden="false" tabindex="0" class="w-full h-full shadow-lg rounded-lg"></iframe>
                </div>
            </div>
        </MainLayout>
    )
}
<iframe title="LameDicinaIframe" src="" width="600" height="450" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>