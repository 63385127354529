import React from 'react'

export default function Categories() {

    const categories = [
        {
            id: "1",
            name: "Fertilizantes",
            link: "/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level1%5D%5B0%5D=cultivo%20>%20fertilizantes",
            image: "https://cdn.lamedicina.cl/carousel/1.png"
        },
        {
            id: "2",
            name: "Sustratos",
            link: "/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level1%5D%5B0%5D=cultivo%20>%20sustratos",
            image: "https://cdn.lamedicina.cl/carousel/2.png"
        },
        {
            id: "3",
            name: "Semillas",
            link: "/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level1%5D%5B0%5D=cultivo%20>%20semillas",
            image: "https://cdn.lamedicina.cl/carousel/3.png"
        },
        {
            id: "4",
            name: "Iluminación",
            link: "/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level1%5D%5B0%5D=cultivo%20>%20iluminación",
            image: "https://cdn.lamedicina.cl/carousel/4.png"
        },
        {
            id: "5",
            name: "Ventilación",
            link: "https://lamedicina.cl/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level1%5D%5B0%5D=cultivo%20%3E%20ventilacion%20y%20control%20de%20olores",
            image: "https://cdn.lamedicina.cl/carousel/5.png"
        },
        {
            id: "6",
            name: "Carpas",
            link: "https://lamedicina.cl/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level1%5D%5B0%5D=cultivo%20%3E%20carpas%2Farmarios",
            image: "https://cdn.lamedicina.cl/carousel/6.png"
        },
        {
            id: "7",
            name: "Bienestar",
            link: "/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level0%5D%5B0%5D=bienestar",
            image: "https://cdn.lamedicina.cl/carousel/7.png"
        },
        {
            id: "8",
            name: "Parafernalia",
            link: "/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level0%5D%5B0%5D=parafernalia",
            image: "https://cdn.lamedicina.cl/carousel/8.png"
        },
        {
            id: "9",
            name: "Papelería",
            link: "/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level0%5D%5B0%5D=Tabaquería&lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level2%5D%5B0%5D=tabaquería%20>%20todos%20>%20papelillos&lamedicina_ecommerce_PROD_products%5Bhirearchical_categories.level1%5D%5B0%5D=todos%20>%20todos",
            image: "https://cdn.lamedicina.cl/carousel/9.png"
        }
    ]

    return (
        categories.map(category => {
            return (
                <div key={category.id} className="cursor-pointer transition ease-out transform-gpu col-12 col-md-6 col-lg-4 hover:scale-110">
                    <a href={category.link}>
                        <img src={category.image} alt={category.name} />
                    </a>
                </div>)
        })
    )
}
