/**
 * TODO:
 * agregar un metodo para vaciar el carrito completo con un boton.
 */
import React from 'react'
import { ShoppingBagIcon } from '@heroicons/react/solid'
export default function CartTitle({ total, title }) {
    return (
        <div className="w-full flex justify-between items-center">
            <h1 className="transition-colors cursor-pointer hover:text-medicina-verde-500 uppercase medicina-font text-2xl text-medicina-cafe-500 flex-grow pt-1">{title}</h1>
            <span className="px-4 py-1 bg-gray-200 rounded-3xl shadow-inner text-medicina-verde-600 font-black flex items-center"><ShoppingBagIcon className="w-4 h-4 inline-block mr-2 text-gray-400" /> {total} </span>
        </div>
    )
}
