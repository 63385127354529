import { useState, useEffect } from 'react'

export default function BrandComponent({ productId = false }) {
    const [brand, setBrand] = useState(null)
    useEffect(() => {
        if (productId && !brand) {
            const url = process.env.REACT_APP_API + '/brand/product/' + productId
            console.log(url)
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    setBrand(data)
                })
        }
    })

    return (
        <div>{brand ? brand.brand.name : 'Genérico'}</div>
    )
}
