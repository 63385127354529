import { useState, useEffect } from 'react'
import PriceComponent from '../../components/PriceComponent/PriceComponent'
import AddToCartComponent from '../../components/AddToCartComponent/AddToCartComponent'
import SkeletonImage from '../../components/skeletonImage/SkeletonImage'
import { GiftIcon, ReceiptTaxIcon } from '@heroicons/react/outline';

export default function ProductCard({ pack = false, isBundle = false, insale = false, descuento = false, imageUrl, attachment, brand, productId, productName = false, productSlug = false, bsaleId = false, price, prevPrice }) {

    const [imageLoaded, setImageLoaded] = useState(false);
    const [grayFilter, setGrayFilter] = useState(false)
    const attachmentObject = attachment;
    const [image, setImage] = useState(false);

    useEffect(() => {
        if (imageUrl) return setImage(imageUrl);
        if (!attachmentObject) return setImage('https://cdn.lamedicina.cl/static/img/noimage.png')
        const url = process.env.REACT_APP_API + '/attachment/' + attachmentObject.attachment_id
        fetch(url)
            .then(res => res.json())
            .then(res => {
                setImage("https://cdn.lamedicina.cl/" + res.filename)
            })
            .catch(err => setImage('https://cdn.lamedicina.cl/static/img/noimage.png'))
    }, [attachmentObject, imageUrl, setImage])

    const getStockFromChildren = (stock) => {
        setGrayFilter(!stock)
    }

    return (
        <div className="shadow-sm bg-white rounded w-full h-full flex flex-col justify-start items-center pb-2 max-w-sm relative" style={{ maxHeight: '500px' }}>
            <div className='absolute top-1 right-2 w-32 z-20 pt-2'>
                {isBundle && <div className='text-white px-3  text-sm font-semibold py-1 rounded-2xl bg-yellow-600 z-20 mb-2 shadow flex items-center'><GiftIcon className='h-5 w-5 mr-2' /> Promo</div>}
                {insale && <div className='text-white px-3 text-sm font-semibold py-1 rounded-2xl bg-red-600 z-20 shadow flex items-center'><ReceiptTaxIcon className='h-5 w-5 mr-2' /> -{Math.floor(100 - (price * 100 / prevPrice))}%</div>}
            </div>
            <div className={`h-60 pt-3 px-2 w-full flex-shrink-0 ${grayFilter ? 'filter grayscale opacity-50' : ''} `}>
                {(imageLoaded === false) && <SkeletonImage />}
                {image && (
                    <a href={`/producto/${productSlug}-${productId}`}>
                        <img src={image} alt={productName} className={`${imageLoaded ? 'opacity-100' : 'opacity-0'} h-full w-auto m-auto transition-all hover:scale-105 transform-gpu`} onLoad={() => setImageLoaded(true)} />
                    </a>
                )}

            </div>

            <div className="px-3 pt-3 text-gray-800 flex flex-col w-full flex-grow">

                <div className="flex-grow h-full text-center mb-4">

                    <div className="flex flex-col h-full">

                        <div className="self-start w-full">
                            <div className="text-xs uppercase font-light text-medicina-cafe-700">
                                {brand ?? 'Genérico'}
                            </div>
                        </div>

                        <div className="flex-grow pb-8">
                            <div className="mt-1 overflow-y-auto font-bold text-gray-800 line-clamp-2">
                                <a href={'/producto/' + productSlug + '-' + productId}>{productName}</a>
                            </div>
                        </div>


                        <div className="w-full px-3 space-y-2 medicina-font text-lg flex flex-grow flex-col justify-end h-full">
                            <div className="text-2xl font-black text-medicina-verde-500">
                                <PriceComponent price={price ?? false} prevPrice={prevPrice ?? false} productId={productId} />
                            </div>
                            <AddToCartComponent isBundle={true} pack={pack} productId={productId} bsaleId={bsaleId} setGrayFilter={getStockFromChildren} />
                            <a href={"/producto/" + productSlug + "-" + productId} className="block w-full text-center py-1 rounded bg-yellow-400 hover:bg-yellow-600 text-medicina-cafe-400 hover:text-medicina-cafe-500 transition-colors ease-in-out">Ver más información</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
