import { useEffect, useState } from 'react'
import algoliasearch from "algoliasearch/lite";
import { useParams } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
import MainLayout from '../../components/Layout/MainLayout';
import Hit from "../AlgoliaSearch/Hit";
import { Hits } from "../AlgoliaSearch/Hits";
import { InstantSearch } from "react-instantsearch-hooks";
import { RefinementList } from "../../pages/AlgoliaSearch/RefinementList";
import { Pagination } from "../CategoryPage/Pagination";

export default function BrandPage() {

    const indexName = "lamedicina_ecommerce_PROD_products";
    const searchClient = algoliasearch(
        "1AW7QK3708",
        "0078f54885ff4a4a96ed89a721d1d562"
    );
  
    const [state, setState] = useState(false)
    const [searchResults, setSearchResults] = useState(false)
    const [page] = useState(1)

    let { brandName, brandId } = useParams()

    useEffect(() => {
        const url = `${process.env.REACT_APP_API}/brands/${brandId}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const search = `${process.env.REACT_APP_API}/producto/buscar?brands=${data.name}`
                setState(data)
                fetch(search)
                    .then(res => res.json())
                    .then(data => {
                        console.log(searchResults)
                        setSearchResults(data)
                    })
                    .catch(err => console.log(err))

            }).catch(err => console.log(err))
    }, [brandId, searchResults])

    useEffect(() => {
        window.scrollTo(0, 0)
        const search = `${process.env.REACT_APP_API}/producto/buscar?brands=${state.name}&page=${page}`
        fetch(search)
            .then(res => res.json())
            .then(data => {
                setSearchResults(data)
            })
    }, [page, state, brandId])


    return (
        <MainLayout>
            <InstantSearch searchClient={searchClient} indexName={indexName} routing={true}>
        <div className="w-full min-h-screen flex md:space-x-4">

          <div className={`hidden  w-60 md:relative md:inline-block z-10 md:w-60 mr-4 px-4 bg-gray-100 py-4 overflow-y-auto h-full pb-48 shadow-2xl`} >
              
              <h1 className="uppercase font-black mb-2 mt-4 text-xs">Marcas</h1>
            <RefinementList attribute="brand" selected={brandName} />
            <h1 className="uppercase font-black mb-2 mt-2 text-xs">
              Categorías
            </h1>
            <RefinementList
              attribute="hirearchical_categories.level0"
              level="0"
            />


                <h1 className="uppercase font-black mb-2 mt-2 text-xs">
                  Sub Categoría
                </h1>
                <RefinementList
                  attribute="hirearchical_categories.level1"
                  level="1"
                />

                <h1 className="uppercase font-black mb-2 mt-2 text-xs">
                  Categoria final
                </h1>
                <RefinementList
                  attribute="hirearchical_categories.level2"
                  level="2"
                />

            
          </div>
          <div className="w-full">
          <div className="w-full h-72 overflow-hidden shadow-inner">
                {state &&
                    <Tilt
                        className="parallax-effect-img"
                        tiltMaxAngleX={0.8}
                        tiltMaxAngleY={0.8}
                        perspective={200}
                        transitionSpeed={7000}
                        scale={1.08}
                        gyroscope={true}>
                        <header role="banner" id="brand" className="flex justify-center overflow-hidden bg-no-repeat bg-cover bg-center" style={{ backgroundImage: state.backdrop ? 'url(https://cdn.lamedicina.cl/' + state.backdrop.file.filename + ')' : 'url(https://cdn.lamedicina.cl/)', height: "500px" }}>
                            <img src={state.logo ? 'https://cdn.lamedicina.cl/' + state.logo.file.filename : 'https://cdn.lamedicina.cl/'} alt="La Medicina" className="h-72 w-auto  inner-element" />
                        </header>
                    </Tilt>
                }
            </div>
            <div className="w-full md:flex justify-between items-center px-4 pt-4">
              <div className="hidden md:inline-block">
                {/* <BreadCrumbs data={currCat.full_category_list} /> */}
              </div>
              <div className="rounded-3xl bg-gray-300 shadow-inner text-gray-600 px-5 py-1 medicina-font text-center md:text-right md:mt-0 mt-2">
                {/* {state.nbHits + " resultados"} */}
              </div>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 p-4 border-green-300 border-dashed">
              <Hits hitComponent={Hit} />
            </div>

            <div className="my-5">
              <Pagination />
            </div>
          </div>
        </div>
      </InstantSearch>


        </MainLayout>
    )
}
