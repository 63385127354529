import algoliasearch from "algoliasearch";
import { useEffect, useState } from 'react'
import ProductCard from '../../pages/CategoryPage/ProductCard'

export default function Recent({ origin }) {

    const [productData, setProductData] = useState(false)

    useEffect(() => {
        let params = { hitsPerPage: 8 }

        const setOrigin = (origin) => {

            switch (origin) {
                case "latest":
                    params = { ...params, filters: 'isPack:false' }
                    return "recientes"
                case "packs":
                    params = { ...params, filters: 'isPack:true' }
                    return "recientes"
                case "sale":
                    const d = new Date()
                    params = { ...params, filters: `insale:true` }
                    // params = { ...params, filters: `insale:true AND sale_timestamp > ${Math.floor(d.setDate(d.getDate() - 30) / 1000)}` }
                    return "ofertas"
                default:
                    return "recientes"
            }
        }

        if (!productData) {
            const searchClient = algoliasearch(
                "1AW7QK3708",
                "0078f54885ff4a4a96ed89a721d1d562"
            )
            console.log(origin)
            const index = searchClient.initIndex(setOrigin(origin))
            index.search("", params)
                .then(function (response) {
                    console.log(response.hits)
                    setProductData(response.hits)
                })
        }
        return () => false;
    }, [productData, origin])



    return (
        productData &&
        productData.map(product => <ProductCard key={'product-card-' + product.product_id}
            attachment={product.attachment}
            productSlug={product.product_slug}
            imageUrl={product.image_url}
            productId={product.product_id}
            productName={product.name}
            brand={product.brand ?? 'Genérico'}
            bsaleId={product.bsale_id}
            price={product.price ?? false}
            prevPrice={product.prev_price ?? false}
            insale={product.insale ?? false}
            isBundle={product.isPack ?? true}
            pack={product.pack ?? false}
        />)

    )
}
