import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'

export default function AboutPage() {
    return (
        <MainLayout>
            <div className="w-11/12 mx-auto pt">
                <div className="pl-2 pt-10">
                    <h1 className="text-4xl text-medicina-cafe-500 font-black leading-none medicina-font">Sobre nosotros</h1>
                    {/* <h6 className="text-xl font-light italic text-medicina-verde-500">Todos los productos de tus marcas favoritas.</h6> */}
                </div>
                <div className="px-6 text-justify">
                    <div className="pl-2 block mt-6">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                    </div>

                    <div className="pl-2 block mt-3">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                    </div>

                    <div className="pl-2 block mt-3">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque molestiae necessitatibus accusantium distinctio ullam mollitia asperiores reprehenderit qui doloribus sed quam recusandae obcaecati sunt voluptatibus, assumenda voluptate rerum nulla omnis.
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}
