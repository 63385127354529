/**
 * LA MEDICINA TIENDA DE CULTIVO - PUNTO DE ENTRADA
 * Enruta las llamadas a index.html, utilizando la pagina Home
 * como punto de partida.
 * 
 * @author Fabián Vásquez <fabian@chulengogames.cl>
*/
import { useEffect } from 'react'
import { Switch, Route } from "react-router-dom"
import CartState from './shared/ShoppingCart/CartState'
import ReactGA from 'react-ga'

/** Paginas */
import ProductPage from './pages/ProductPage/ProductPage'
import CategoryPage from './pages/CategoryPage/CategoryPage'
import BrandsPage from './pages/BrandsPage/BrandsPage'
import BrandPage from './pages/BrandPage/BrandPage'
import AboutPage from './pages/AboutPage/AboutPage'
import LocationPage from './pages/LocationPage/LocationPage'
import SalesPage from './pages/SalesPage/SalesPage'
import Promo from './pages/SalesPage/Promo'
// import NotFound from './pages/NotFound/NotFound';
// import Contact from './pages/Contact/Contact';

/** Estaticos y compartidos*/
import './index.css';
import ContactPage from './pages/ContactPage/ContactPage'
import BlogPage from './pages/BlogPage/BlogPage'
import PaymentPage from './pages/PaymentPage/PaymentPage'
import PaymentStatus from './pages/PaymentPage/PaymentStatus'
import SearchPage from './pages/SearchPage/SearchPage'
import TermsPage from './pages/TermsPage/TermsPage'
import AlgoliaSearch from './pages/AlgoliaSearch/AlgoliaSearch'
import NewHome from './pages/NewHome/NewHome'

const TRACKING_ID = 'G-PMN3PMQJJP'
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <CartState>
      <Switch>
        <Route exact={true} path="/" component={NewHome} />
        <Route exact={true} path="/producto/:slug" component={ProductPage} />
        <Route exact={true} path="/categorias/:parent" component={CategoryPage} />
        <Route exact={true} path="/categorias/:parent/:child" component={CategoryPage} />
        <Route exact={true} path="/categorias/:parent/:child/:grandchild" component={CategoryPage} />
        <Route exact={true} path="/marcas" component={BrandsPage} />
        <Route exact={true} path="/marcas/:brandName/:brandId" component={BrandPage} />
        <Route exact={true} path="/tiendas" component={LocationPage} />
        <Route exact={true} path="/quienes-somos" component={AboutPage} />
        <Route exact={true} path="/contacto" component={ContactPage} />
        <Route exact={true} path="/blog-y-noticias" component={BlogPage} />
        <Route exact={true} path="/pagar/orden/:idOrden" component={PaymentPage} />
        <Route exact={true} path="/compra" component={PaymentStatus} />
        <Route exact={true} path="/buscar" component={SearchPage} />
        <Route exact={true} path="/terminos-condiciones" component={TermsPage} />
        <Route exact={true} path="/algolia-test" component={AlgoliaSearch} />
        <Route exact={true} path="/ofertas" component={SalesPage} />
        <Route exact={true} path="/promos" component={Promo} />
      </Switch>
    </CartState>
  )
}

export default App;
