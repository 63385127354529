import { LocationMarkerIcon } from "@heroicons/react/outline";

export default function Entrega({ direccion = false, ciudad = false, region = false }) {



    return (
        <div className="pt-1">
            
            {direccion && ciudad && region &&
                <>
                    <h2 className="block font-semibold">Despacho a domicilio</h2>
                    <div className="flex space-x-3 mt-2">
                        <div><LocationMarkerIcon className="w-8 h-8 text-medicina-verde-600" /></div>
                        <div><span className="text-sm block">{direccion}</span>
                            <span className="text-sm">{ciudad}, {region}</span></div>
                    </div>
                </>
            }

            { (!direccion || !ciudad || !region) && 
                <h2 className="block font-semibold">Retiro en tienda</h2>
            }

        </div>
    )

}
