import { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { useParams } from 'react-router'
import MainLayout from '../../components/Layout/MainLayout'
import OutOfStockAlert from './OutOfStockAlert'
import BrandComponent from '../../components/BrandComponent/BrandComponent'
import Delivery from './Delivery'
import ClientInfo from './ClientInfo'
import ConfirmClientInfo from './ConfirmClientInfo'

export default function PaymentPage() {

    const [buyOrder, setBuyOrder] = useState(false)
    const [tbk, setTbk] = useState(false)
    const [clientOrder, setClientOrder] = useState(false)

    let { idOrden } = useParams()
    const [outOfStock, setOutOfStock] = useState(false)



    useEffect(() => {
        setOutOfStock(window.localStorage.getItem("user_orders") ? JSON.parse(window.localStorage.getItem("user_orders")) : false)

        if (idOrden) {
            const url = `${process.env.REACT_APP_API}/buyOrder/${idOrden}`
            return fetch(url)
                .then(res => res.json())
                .then(data => {
                    setBuyOrder(data)
                }).catch(err => console.log(err))
        }
    }, []);

    useEffect(() => {
        if (buyOrder && clientOrder) {
            const url = `${process.env.REACT_APP_API}/buyOrder/pay/${idOrden}`
            return fetch(url)
                .then(res => res.json())
                .then(data => {
                    setTbk(data)
                }).catch(err => setTbk(false))
        }
    }, [clientOrder, buyOrder]);

    const cancelarCompra = () => {
        window.localStorage.removeItem("cart")
        window.localStorage.removeItem("user_orders") // deberiamos borra o anular la fila igual.
        window.location.href = "/"
    }


// hay que validar si es que ya existe una cliente en la orden.
    return (
        <MainLayout>
            {buyOrder &&
                <div className="pb-10">

                    <div className="w-11/12 mx-auto mb-16">
                        <div className="pl-2 pt-10">
                            <h1 className="text-4xl text-medicina-cafe-500 font-black leading-none medicina-font">Resumen de su compra</h1>
                            <h6 className="text-xl font-light italic text-medicina-verde-500 mb-3">Completa tu información para finalizar tu compra.</h6>
                            <p className="text-sm text-justify pt-6">Verifica que todos los productos que quieres adquirir se encuentra en la lista. Revisa las unidades y montos antes de confirmar.
                                No olvides rellenar la información de contacto y de indicarnos si deseas despacho o bien retiras en alguna de nuestras tiendas.
                                <strong>Tu información personal y sensible será eliminada de nuestros registros una vez se complete la venta.</strong>
                            </p>
                        </div>
                    </div>


                    <div className="w-11/12 mx-auto flex flex-col-reverse md:flex-row md:space-x-8 border-t border-gray-300 py-10 px-2">

                        <div className="w-full md:w-1/2">

                            <div className="mb-10 md:pr-14">
                                <h2 className="medicina-font text-medicina-cafe-500 text-3xl mb-3">Detalle de Entrega</h2>
                                <p className="text-sm">
                                    Rellena la información del formulario para poder hacer entrega de tus productos. Recuerda que realizamos envios <span className="font-bold">a todo chile</span> por pagar.
                                    Recuerda además leer nuestros <a className="text-medicina-cafe-600 hover:text-medicina-verde-600 underline hover:no-underline" href="/terminos-condiciones">terminos y condiciones de uso</a> antes de finalizar tu compra.
                                </p>
                            </div>

                            <div className="md:pr-14">
                                <ClientInfo clientOrder={clientOrder} />
                                <Delivery />
                                <ConfirmClientInfo idOrden={idOrden} setClientOrder={setClientOrder} clientOrder={clientOrder} />
                            </div>

                        </div>



                        <div className="w-full lg:w-1/2 mb-10 md:mb-0 flex flex-wrap flex-col">
                            <div className='sticky top-28'>

                                <OutOfStockAlert products={buyOrder.no_stock.length > 0 ? buyOrder.no_stock : false} />

                                <table className="table text-white divide bg-medicina-cafe-500 w-full text-center rounded shadow">
                                    <thead className="">
                                        <tr>
                                            <th className="p-3 uppercase text-xs text-left">Producto</th>
                                            <th className="p-3 uppercase text-xs text-left">Marca</th>
                                            <th className="p-3 uppercase text-xs">Unidades</th>
                                            <th className="p-3 uppercase text-xs">Precio Unitario</th>
                                            <th className="p-3 uppercase text-xs">Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-xs text-gray-800 bg-white rounded-b">
                                        {
                                            buyOrder.items.map(item => {
                                                return (
                                                    <tr key={'item-orden-' + item.id} className="border-b">
                                                        <td className="p-3 text-left">{item.product.name}</td>
                                                        <td className='p-3 text-left'><BrandComponent productId={item.product.id} /></td>
                                                        <td className="p-3 text-center font-black">{item.quantity}</td>
                                                        <td className="p-3 text-center">$ <NumberFormat value={parseInt(item.amount)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                                        <td className="p-3 text-center font-bold">$ <NumberFormat value={parseInt(item.amount * item.quantity)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                                <div className="bg-white w-full mx-auto rounded mt-5 p-4 text-right shadow md:flex block justify-center md:justify-between items-center">
                                    <div className="w-full md:w-auto block text-center md:text-left mb-2">
                                        <span className="w-full text-medicina-cafe-500 block font-light uppercase text-2xl">Total:</span>
                                        <h1 className="uppercase medicina-font text-medicina-verde-500 text-4xl">$<NumberFormat className="font-black" value={buyOrder.order.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></h1>
                                    </div>

                                    <div className="gap-1 flex">
                                        <button onClick={() => { cancelarCompra() }} className="transition-colors ease-in-out hover:bg-red-900 inline-block px-4 py-2 rounded bg-red-700 text-white">Cancelar Compra</button>
                                        <a href="/" className="transition-colors ease-in-out hover:bg-medicina-verde-800 inline-block px-4 py-2 rounded bg-medicina-verde-500 text-white">Seguir Comprando</a>
                                        {tbk && clientOrder &&
                                            <form action={tbk.url}>
                                                <input type="hidden" name="token_ws" value={tbk.token} />
                                                <button className="transition-colors ease-in-out inline-block px-4 py-2 rounded bg-green-600 text-white">Pagar con WebPay</button>
                                            </form>
                                        }
                                        { (clientOrder === false || tbk === false) &&
                                            <div>
                                                <input type="hidden" name="token_ws" />
                                                <button disabled className="transition-opacity ease-in-out inline-block px-4 py-2 rounded bg-gray-400 text-gray-300 cursor-not-allowed">Pagar con WebPay</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>



                </div>
            }
        </MainLayout>
    )
}
