import { ADD_TO_CART, GET_CART, REMOVE_FROM_CART, SHOW_CART, SUM_TO_CART, CLEAR_CART, UPDATE_CLIENT_INFO} from "./types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    const { payload, type } = action

    switch (type) {
        case GET_CART:
            window.localStorage.setItem("cart", JSON.stringify(state))
            return {
                ...state,
                cart: payload
            }

        case ADD_TO_CART:
            window.localStorage.setItem("cart", JSON.stringify(state))
            return { ...state }

        case REMOVE_FROM_CART:
            window.localStorage.setItem("cart", JSON.stringify(state))
            return { ...state }
        
        case SUM_TO_CART:
            window.localStorage.setItem("cart", JSON.stringify(state))
            return { ...state }

        case SHOW_CART:
            window.localStorage.setItem("cart", JSON.stringify(state))
            return { ...state }
        
        case CLEAR_CART:
            window.localStorage.removeItem("cart")
            return {...state}
        
        case UPDATE_CLIENT_INFO:
            window.localStorage.setItem("cart", JSON.stringify(state))
            return { ...state }
            
        default:
            return state
    }
}