import MainLayout from '../../components/Layout/MainLayout'
import SalesComponent from './SalesComponent';

export default function Promo() {


    return (
        <MainLayout>
            <div className="flex flex-col w-9/12 mx-auto items-starts justify-start min-h-screen">
                <div className='py-6'>
                    {/* <h1 className='text-5xl text-medicina-cafe-500 medicina-font'>Productos con descuento!</h1> */}
                    <img src='https://cdn.lamedicina.cl/promo/header.png' className='w-full' title='Productos con descuento' />
                </div>

                <SalesComponent origen="promos" />
                
            </div>
        </MainLayout>
    )
}
