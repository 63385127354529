import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


export default function BrandWidgetComponent() {

    const [state, setState] = useState(false)

    useEffect(() => {
        const url = `${process.env.REACT_APP_API}/brands`
        return fetch(url)
            .then(res => res.json())
            .then(data => {
                console.log('datos: ', data)
                setState(data)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <div className="w-auto mx-auto mb-10 gap-3 flex flex-wrap flex-row justify-center relative z-10">

            {state &&
                state.map(brand => {
                    return (brand.logo !== null && brand.backdrop !== null) &&
                        <Link to={'/categorias/productos?lamedicina_ecommerce_PROD_products[refinementList][brand][0]='+brand.name} key={'widget-marca-' + brand.id} className="w-90 h-20 inline-block">
                            <div className="relative w-full h-full object-cover">
                                <img src={'https://cdn.lamedicina.cl/' + brand.backdrop.file.filename} alt="backdrop" className="rounded absolute z-10 w-full h-auto object-cover" />
                                <img src={'https://cdn.lamedicina.cl/' + brand.logo.file.filename} alt="logo" className="h-full w-auto relative z-10" />
                            </div>
                        </Link>
                }
                )
            }
        </div>

    )
}
