import React, { useEffect, useState } from 'react'
import Moment from 'moment'
import 'moment/locale/es-mx'
export default function PostComponent({ title, image, excerpt, media, date, link }) {

    const [imagen, setImagen] = useState(false)
    useEffect(() => {
        if (!imagen && media) {
            async function getImage() {
                const res = await fetch("https://blog.lamedicina.cl/wp-json/wp/v2/media/" + media)
                const data = await res.json()
                return setImagen(data)
            }
            getImage()
        }

    }, [imagen, media])

    const handleClick = () => {
        window.open(link, '_blank')
    }

    return (
        imagen &&
        <div className="relative cursor-pointer overflow-hidden shadow rounded-xl" onClick={handleClick} >
            <div className="transition-all absolute bottom-0 left-0 w-full py-4 bg-medicina-verde-900 bg-opacity-70 text-white px-2 hover:bg-opacity-95 min-h-2/5 backdrop-filter backdrop-blur">
                <small>{Moment(date).locale('es-mx').format('D MMMM HH:mm')}</small>
                <h1 className="medicina-font text-3xl">{title}</h1>
            </div>
            <img className="w-full z-10" src={imagen.media_details.sizes.medium.source_url} alt={title} />
        </div>
    )
}