import Hit from "../AlgoliaSearch/Hit";
import { Hits } from "../AlgoliaSearch/Hits";
import MainLayout from "../../components/Layout/MainLayout";
import { useRef } from "react";
import { RefinementList } from "../../pages/AlgoliaSearch/RefinementList";
import { useParams } from "react-router-dom";
import { Pagination } from "./Pagination";


export default function CategoryPage() {

  let category = useParams();
  const layout = useRef();
  const results = useRef();


  return (
    <MainLayout>

      <div className="w-full min-h-screen flex md:space-x-4">

        {/* <div className="md:hidden">
          <MobileSidebar />
        </div> */}

        <div
          className={`hidden  w-60 md:relative md:inline-block z-10 md:w-60 mr-4 px-4 bg-gray-100 py-4 overflow-y-auto h-full pb-48 shadow-2xl`}
        >
          <h1 className="uppercase font-black mb-2 mt-2 text-xs">
            Categorías
          </h1>
          <RefinementList
            test={true}
            attribute="hirearchical_categories.level0"
            level="0"
          //selected={category.parent ? category.parent.toLowerCase() : false}
          />

          {category.parent && (
            <>
              <h1 className="uppercase font-black mb-2 mt-2 text-xs">
                Sub Categoría
              </h1>
              <RefinementList
                attribute="hirearchical_categories.level1"
                level="1"
                selected={
                  category.child
                    ? category.parent.toLowerCase() +
                    " > " +
                    category.child.toLowerCase()
                    : false
                }
              />
            </>
          )}

          {category.parent && (
            <>
              <h1 className="uppercase font-black mb-2 mt-2 text-xs">
                Categoria final
              </h1>
              <RefinementList
                attribute="hirearchical_categories.level2"
                level="2"
                selected={
                  category.grandchild
                    ? category.parent.toLowerCase() +
                    " > " +
                    category.child.toLowerCase() +
                    " > " +
                    category.grandchild.toLowerCase()
                    : false
                }
              />
            </>
          )}

          <h1 className="uppercase font-black mb-2 mt-2 text-xs">
            Precio
          </h1>
          <RefinementList attribute="price" />

          <h1 className="uppercase font-black mb-2 mt-4 text-xs">Marcas</h1>
          <RefinementList attribute="brand" />

          <h1 className="uppercase font-black mb-2 mt-4 text-xs">Descuento</h1>
          <RefinementList attribute="insale" />
          
        </div>
        <div className="w-full" ref={layout}>


          <div ref={results}>
            <Hits hitComponent={Hit} searchbox={false} />
          </div>

          <div className="my-5">
            <Pagination />
          </div>
        </div>
      </div>

    </MainLayout>
  );
}
