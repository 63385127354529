import { useEffect, useState } from 'react'

export default function InstagramComponent() {

    const [posts, setPosts] = useState(false)

    useEffect(() => {
        if (!posts) {
            const url = process.env.REACT_APP_API + "/instagram"
            return fetch(url)
                .then(res => res.json())
                .then(data => setPosts(data.slice(0, 6)))
        }
    }, [posts, setPosts])

    return (
        <div className="w-full">
            <div className="w-full text-left mb-6 ">
                <div className="pl-2">
                    <h1 className="text-4xl text-medicina-cafe-500 font-black leading-none medicina-font">Instagram</h1>
                    <h6 className="text-xl font-light italic text-medicina-verde-500">@lamedicinatiendadecultivo</h6>
                </div>
            </div>
            
            {!posts && <div className="w-full h-full flex justify-center items-center"><div className="germinando transform scale-50"></div></div>}
            
            {posts && (
                <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-2 grid-rows-3 gap-3 lg:gap-3">
                    { posts.map(post => <div key={post.id} onClick={ () => window.location.href = post.permalink } className="cursor-pointer max-h-64 overflow-hidden border object-contain bg-white rounded-lg shadow"><img className="p-1 object-cover h-full w-full rounded shadow bg-white" src={ post.media_type === "VIDEO" ? post.thumbnail_url : post.media_url } alt="" /></div> )} 
                </div>
            )}

        </div>
    )
}
