import React, { useReducer } from 'react'
import CartReducer from './CartReducer'
import CartContext from './CartContext'

const CartState = (props) => {
    const _LS = window.localStorage.getItem("cart")

    let initialState = {
        cart: [],
        totalProductos: 0,
        showCart: false,
        clientInfo: {}
    }

    if (_LS) { initialState = JSON.parse(_LS) }

    const [state, dispatch] = useReducer(CartReducer, initialState)

    const clearCart = () => {
        dispatch({ payload: [], type: 'CLEAR_CART' })
    }

    const addToCart = async (productId, productQuantity) => {
        const _curState = state
        let totalItems = 0
        const _productIndex = state.cart.length > 0 ? state.cart.map(item => item.productId).indexOf(productId) : -1
        if (_productIndex === -1) _curState.cart = [..._curState.cart, { productId: productId, quantity: productQuantity }]
        if (_productIndex !== -1) _curState.cart[_productIndex].quantity = _curState.cart[_productIndex].quantity + productQuantity
        _curState.cart.map(item => totalItems += item.quantity)
        _curState.totalProductos = totalItems
        dispatch({ payload: _curState, type: 'ADD_TO_CART' })
    }

    const removeFromCart = async (index) => {
        let _curState = state
        let totalItems = 0
        _curState.cart.splice(index,1)
        _curState.cart.map(item => totalItems += item.quantity)
        _curState.totalProductos = totalItems
        dispatch({ payload: _curState, type: 'REMOVE_FROM_CART' })
    }

    const sumItem = async (index, productQuantity) => {
        let _curState = state
        let totalItems = 0
        _curState.cart[index].quantity = parseInt(productQuantity)
        console.log(_curState.cart[index].quantity)
        _curState.cart.map(item => totalItems += parseInt(item.quantity))
        _curState.totalProductos = totalItems
        dispatch({ payload: _curState, type: 'SUM_TO_CART' })
    }

    const toggleCart = () => {
        let _curState = state
        _curState.showCart = !_curState.showCart
        dispatch({
            payload: { _curState },
            type: "SHOW_CART"
        })
    }

    const updateClientInfo = (clientInfo) => {
        let _curState = state
        _curState.clientInfo = {..._curState.clientInfo, ...clientInfo}
        dispatch({
            payload: { _curState },
            type: "UPDATE_CLIENT_INFO"
        })
    }

    return (
        <CartContext.Provider value={{
            state: state,
            addToCart,
            removeFromCart,
            toggleCart,
            sumItem,
            clearCart,
            updateClientInfo
        }}>
            {props.children}
        </CartContext.Provider>
    )
}

export default CartState