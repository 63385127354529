import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import MainLayout from '../../components/Layout/MainLayout'
import GalleryComponent from '../../components/GaleryComponent/GaleryComponent'
import PriceComponent from '../../components/PriceComponent/PriceComponent'
import StockComponent from './StockComponent'
import DescriptionComponent from './DescriptionComponent'
import SiblingsComponent from '../../components/SiblingsComponent/SiblingsComponent'
import AddToCartComponent from '../../components/AddToCartComponent/AddToCartComponent'
import NumberFormat from 'react-number-format'
import { GiftIcon } from '@heroicons/react/solid'

export default function ProductPage() {

    let { slug } = useParams()
    const [product, setProduct] = useState()

    useEffect(() => {
        const id = slug ? slug.split('-') : false
        const url = process.env.REACT_APP_API + '/products/' + id[id.length - 1]
        return fetch(url)
            .then(res => res.json())
            .then(data => {
                if (data.error) {
                    console.log(data.error)
                    //window.location = '/404'
                    return
                }
                console.log("producto", data)
                setProduct(data.data)
            })
            .catch((err) => {
                console.log(err)
                //  window.location = '/404'
            })
    }, [slug])

    return (
        <MainLayout>
            {product &&
                <>
                    <div className="h-full block lg:flex justify-center w-11/12 md:w-10/12 xl:w-9/12 mx-auto lg:space-x-12 pt-10 relative pb-10">

                        <div className="md:hidden md:scale-0 transform ">
                            <div className='w-full flex'>
                                <span className="inline-block text-sm mb-1 text-gray-400 uppercase hover:text-medicina-verde-500 transition-colors">
                                    {product.brand ? product.brand.name : 'genérico'}
                                </span>
                            </div>

                            <h1 className="font-bold text-medicina-verde-600 text-3xl inline-block pr-4 uppercase">{product.name}</h1>
                            <div className="border-b-4 border-medicina-verde-500 w-16 pt-4 mb-6"></div>
                            <div className="grid grid-rows-1 grid-cols-3 content-center">
                                {product.price && product.price[1] && product.price[1].price > product.price[0].price && <div className='block text-sm font-thin italic line-through text-medicina-cafe-600'><NumberFormat value={product.price[1].price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false} prefix={'$'} /></div>}
                                {product.price && <div className='text-medicina-cafe-600 text-3xl'><PriceComponent price={product.price[0] ? product.price[0].price : false} prevPrice={product.price[1] ? product.price[1].price : false} /></div>}
                            </div>
                        </div>

                        <section className="mb-0 lg:w-1/2 md:mb-0  md:pr-10 flex-grow-0">
                            <div className="md:sticky md:top-28">
                                <GalleryComponent attachment={product.attachment} parentAttachment={product.parent_attachment} />
                                <div className="block w-full space-y-2 medicina-font text-3xl pt-1">
                                    <AddToCartComponent color="yellow" productId={product.id} bsaleId={product.bsale_id} pack={ product.pack ?? false } isBundle={ product.pack ?? false } />
                                </div>
                            </div>
                        </section>

                        <div className="w-full lg:w-1/2 md:pr-10">
                            <div className="">
                                <div className="hidden md:block">
                                    <div className='w-full flex items-center justify-between space-x-4'>
                                        <span className="block text-sm mb-1 text-gray-400 uppercase hover:text-medicina-verde-500 transition-colors">
                                            {product.brand ? <a href={'/categorias/productos?lamedicina_ecommerce_PROD_products[refinementList][brand][0]=' + product.brand.name}>{product.brand.name}</a> : 'genérico'}
                                        </span>
                                        {product.pack && <div className='text-white text-sm font-semibold py-1 px-2 rounded-2xl bg-yellow-600 z-20 shadow flex items-center'><GiftIcon className='h-4 w-4 mr-2' /> Promo</div>}
                                    </div>
                                    <h1 className="font-bold text-medicina-verde-600 text-3xl inline-block pr-4 capitalize">{product.name.toUpperCase()}</h1>
                                    <div className="border-b-4 border-medicina-verde-500 w-16 pt-4 mb-6"></div>
                                    <div className="grid grid-rows-1 grid-cols-3 content-center">
                                        <span className="block text-2xl font-semibold">
                                            {product.price && <div className='text-medicina-cafe-600 text-3xl'><PriceComponent price={product.price[0] ? product.price[0].price : false} prevPrice={product.price[1] ? product.price[1].price : false} /></div>}
                                        </span>
                                    </div>
                                </div>

                                <div className="mt-12 mb-12">
                                    { !product.pack && <StockComponent productId={product.id} pack={ product.pack ?? false } /> }
                                </div>

                                <div>
                                    <DescriptionComponent
                                        parentId={product.parent_id}
                                        childDescription={product.description}
                                    />
                                </div>

                                <div>
                                    <SiblingsComponent siblings={product.siblings} activeId={product.id} parentId={product.parent_id} />
                                </div>



                            </div>
                        </div>
                    </div>
                    {/* <div className="w-9/12 mx-auto block">
                        <h1 className="medicina-font text-4xl mt-10 mb-6 text-medicina-verde-500">Cuentanos tu experiencia con éste producto!</h1>
                        <div className="p-4 text-sm bg-green-400 text-green-800 rounded shadow border-8 border-dashed  border-green-100 my-10">
                            Las opiniones vertidas en este espacio son de exclusiva responsabilidad de aquellos que las emiten. 
                            NO acreditamos ningun tipo de violencia y entregamos este espacio esperando generar una comunidad donde nos podamos ayudar unos a otros.
                            Cualquier comentario que amenace este interes comun será eliminado.
                        </div>
                        <DiscussionEmbed
                            shortname='lamedicina'
                            config={
                                {
                                    url: "https://lamedicina-cl-5wdze.ondigitalocean.app/producto/" + product.slug + "-" + product.id,
                                    identifier: product.slug + "-" + product.id,
                                    title: "La medicina Tienda de cultivo - " + product.slug + "-" + product.id,
                                    language: 'es_CL' //e.g. for Traditional Chinese (Taiwan)	
                                }
                            }
                        />
                    </div> */}
                </>
            }

        </MainLayout >
    )
}
