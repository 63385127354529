import React, { useState, useEffect } from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import PostComponent from '../../components/BlogComponent/PostComponent'
import InstagramComponent from '../../components/InstagramComponent/InstagramComponent'

export default function BlogPage() {

    const [posts, setPosts] = useState(false)

    useEffect(() => {
        if (!posts) {
            const url = "https://blog.lamedicina.cl/wp-json/wp/v2/posts"
            return fetch(url)
                .then(res => res.json())
                .then(data => setPosts(data))
        }
    }, [posts, setPosts])

    return (
        <MainLayout>

            <section className="w-11/12 mx-auto pb-20" id="articulos">
                <div className="mt-10 flex space-x-12">
                    
                    <div className="w-2/3">
                        
                        <div className="mx-auto pt">
                            <div className="mb-8">
                                <h1 className="text-4xl te∆xt-medicina-cafe-500 font-black leading-none medicina-font text-medicina-cafe-500">Blog y Noticias</h1>
                                <h6 className="text-xl font-light italic text-medicina-verde-500">Novedades, tips y actualidad.</h6>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-5">
                            {!posts && <div className="w-full h-full flex justify-center items-center"><div className="germinando transform scale-50"></div></div>}
                            {posts &&
                                posts.map(post => {
                                    return <PostComponent link={post.link} key={"post-blog-" + post.id} title={post.title.rendered} media={post.featured_media} excerpt={post.excerpt.rendered.replace(/(<([^>]+)>)/ig, '')} date={post.date_gmt} />
                                })
                            }
                        </div>

                    </div>

                    <div className="w-1/3">
                        <InstagramComponent />
                    </div>
                </div>
            </section>

        </MainLayout >
    )
}
