import React, { useRef } from 'react'
import { toast } from 'react-toastify'

export default function FooterComponent() {

    const clientEmail = useRef(null)
    const suscribirse = () => {
        const url = process.env.REACT_APP_API + '/newsletter'
        const formData = new FormData()
        formData.append('email', clientEmail.current.value)
        fetch(url, {
            method: 'POST',
            body: formData
        })
        .then(res => res.json())
        .then(data => {
            toast("Gracias por suscribirte!")
        })  
    }

    return (
        <footer className="w-full relative z-10 pt-6 md:pt-10 bg-medicina-verde-500 pb-4">

            <div className="absolute top-0 left-0 w-full flex justify-center items-center h-full opacity-5 z-10">
                <a href="/" alt="La Medicina" >
                    <img className="block h-80 w-auto" src="https://cdn.lamedicina.cl/static/img/LOGOMEDICINA.png" alt="La Medicina tienda de Cultivo" />
                </a>
            </div>

            <div className="w-ful bg-medicina-verde-400 text-center border-b-8 border-medicina-verde-500 md:mb-8  relative z-20 mb-10">
                <h1 className="text-medicina-verde-500 font-black uppercase 
                    text-lg leading-3 pt-7
                    sm:text-3xl sm:leading-4 sm:pt-6
                    md:text-5xl md:leading-8 md:pt-9
                    lg:text-6xl lg:pt-12 lg:leading-8
                    ">{  (new Date()).getMonth() >= 7 ? (new Date()).getFullYear() - 2016 : (new Date()).getFullYear() - 2017 } años cultivando medicina</h1>
            </div>

            <div className="w-11/12 mx-auto h-full flex justify-center pb-10 text-medicina-verde-300 mb-3 relative z-20">
                <div className="text-center w-11/12 md:w-6/12">
                    <div className="text-lg md:text-2xl medicina-font mb-2">Suscribete al correo informativo</div>
                    <input ref={clientEmail} type="email" className="w-full border-none rounded-lg bg-medicina-verde-300 mb-4 text-medicina-verde-800" placeholder="nombre@correo.cl" />
                    <button className="bg-medicina-cafe-500 text-white py-2 px-6 rounded-lg text-sm md:text-lg" onClick={suscribirse}>Suscribirme!</button>
                </div>
            </div>

            <div className="w-9/12 mx-auto h-full block md:flex justify-between pb-10 relative z-20 text-sm md:text-xl text-medicina-verde-300 text-center md:text-left">
                
                <div className="flex items-start w-full">
                    <nav className="w-full">
                        <ul className="mb-6 space-y-3 text-md">
                            <li><a href="//blog.lamedicina.cl">Blog</a></li>
                            <li><a href="/ofertas">Ofertas</a></li>
                            <li><a href="//blog.lamedicina.cl">Noticias</a></li>
                            {/* <li><a href="/">Los más vendidos</a></li> */}
                        </ul>

                        <div>
                            <h2 className="font-semibold pb-2">Comunidad</h2>
                            <div className="w-full h-7 flex justify-center md:justify-start items-center space-x-3">
                                <a href="https://www.facebook.com/lamedicinatiendadecultivo" target="_blank" rel="noreferrer" title="Facebook">
                                    <div className="text-white"><svg className="w-6 h-6 text-medicina-verde-300 fill-current" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg></div>
                                </a>
                                <a href="https://www.instagram.com/lamedicinatiendadecultivo" target="_blank" rel="noreferrer" title="Facebook">
                                    <div className="text-white"><svg className="w-6 h-6 text-medicina-verde-300 fill-current" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" /></svg></div>
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
                
                
                <div className="flex justify-center mt-12 md:mt-0 items-start w-full">
                    <nav>
                        <ul className="space-y-3 flex flex-col items-center">
                            <li><a href="/terminos-condiciones">Términos y condiciones</a></li>
                            <li><a href="/terminos-condiciones#devoluciones">Devoluciones y garantías</a></li>
                            <li><a href="/terminos-condiciones#pago">Sistemas de pago</a></li>
                            <li><a href="/terminos-condiciones#envioß">Condiciones de envío</a></li>
                        </ul>
                    </nav>
                </div>

                <div className="flex justify-start flex-col items-end mt-12 md:mt-0 w-full ">
                    <div className="font-light">
                        <h1 className="font-semibold mb-2 text-xl">Agricola La Medicina Ltda.</h1>
                        <div className="text-lg"> 
                            <div className="block mb-3">
                                <span className="block font-semibold">Teléfono:</span>
                                <span>+56 9 8955 0128 (sólo whatsapp)</span>
                            </div>

                            <div className="block mb-3">
                                <span className="block font-semibold">Correo:</span>
                                <span>soporte@lamedicina.cl</span>
                            </div>

                            <div className="block mb-3">
                                <span className="block font-semibold">Horario de contacto:</span>
                                <span className="font-thin block">Lunes - Sabado</span>
                                <span>11:00 am - 20:00 pm</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className="w-full flex justify-center item-center">
                <img src="https://cdn.lamedicina.cl/static/img/TRANSBANK_600.png" alt="Paga con Webpay" className="w-9/12 sm:w-6/12 md:w-3/12" />
            </div>

        </footer>

    )
}
