import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

export default function SalesPaginator({ currentPage, setCurrentPage, totalHits, totalPages, resultsPerPage, realHits }) {

    console.log(realHits)

    const handleClick = (direction) => {
        if (direction === 'left') {
            if (currentPage > 0) {
                setCurrentPage(currentPage - 1)
            }
        }
        if (direction === 'right') {
            if (currentPage < (totalPages - 1)) {
                setCurrentPage(currentPage + 1)
            
            }
        }
        // scroll to top
        window.scrollTo(0, 0)
    }

    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 mb-6 rounded shadow-lg">
            <div className="flex-1 flex justify-between sm:hidden">
                <button
                    onClick={() => handleClick('left')}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Previa
                </button>
                <button
                    onClick={() => handleClick('right')}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Siguiente
                </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Mostrando desde el <span className="font-medium">{(currentPage * resultsPerPage + 1) }</span> al <span className="font-medium">{(currentPage * resultsPerPage + 1) + (realHits - 1)}</span> de{' '}
                        <span className="font-medium">{totalHits}</span> resultados totales
                    </p>
                </div>
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <button
                            onClick={() => handleClick('left')}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Anterior</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                        
                        <button
                            onClick={() => handleClick('right')}
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Siguiente</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    )
}
