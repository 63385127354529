import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import MainLayout from '../../components/Layout/MainLayout';
import isLoadingHOC from '../../shared/isLoadingHOC'
import NumberFormat from 'react-number-format'
import Entrega from './Entrega';
// import { ArrowCircleUpIcon } from '@heroicons/react/solid';

export const PaymentStatus = ({ setLoading }) => {

    const [orden, setOrden] = useState()

    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }

    let query = useQuery()

    useEffect(() => {
        setLoading(true)
        if (query.get("token_ws")) {
            const url = `${process.env.REACT_APP_API}/buyOrder/validate/${query.get("token_ws")}`
            return fetch(url)
                .then(res => res.json())
                .then(data => {
                    if (data) {
                        setOrden(data)
                        console.log(data)
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }, [])

    /** 
     * validamos y pintamos...
    **/
    return (
        <MainLayout>

            {orden && !orden.error &&
                <div className='py-10'>
                    <div className="lg:w-9/12 w-11/12 mx-auto">

                        <div className='w-full flex mb-8'>
                            <div className="flex-grow-0">
                                <h1 className="text-5xl font-black medicina-font text-medicina-verde-500">
                                    {orden.webpay.status === "AUTHORIZED" ? "Pago exitoso" : "No se pudo procesar el pago"}
                                    <small className="block font-light text-lg text-medicina-cafe-500">A continuación el detalle de su compra</small>
                                </h1>
                            </div>

                            <div className="flex-grow text-right medicina-font">
                                <span className="w-full block text-gray-400 text-1xl -mb-2 uppercase">ORDEN de compra:</span>
                                <span className="text-gray-300 text-6xl">
                                    {'#' + orden.id}
                                </span>
                            </div>
                        </div>

                        <div className="flex gap-6 flex-col lg:flex-row justify-center items-center lg:items-stretch h-full">
                            <div className="w-full lg:w-2/3 bg-white rounded p-4 shadow divide-y flex flex-col">
                                <div className="items-stretch h-full">
                                    {orden.products && orden.products.map((product, index) => {
                                        return (
                                            <div className="w-full flex justify-between items-center gap-8 border-b py-2" key={'item-orden-' + product.id}>
                                                {product.product.name}
                                                <div className="font-light text-medicina-verde-600">
                                                    $<NumberFormat value={product.product.price[0].price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                                                    <span className="text-xs font-light text"> (iva incluido)</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="items-end text-right py-3 text-xl">
                                    Total:
                                    <span className="font-semibold"> $<NumberFormat value={orden.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></span>
                                </div>
                            </div>

                            <div className="w-full lg:w-1/3 block bg-white rounded p-5 shadow divide-y space-y-2">
                                <div className="block w-full py-3">
                                    <span className="block uppercase font-black text-xs text-medicina-cafe-500">Nombre:</span>
                                    {orden.client ? orden.client.client.name : 'SIN INFORMACIÓN'} {orden.client ? orden.client.client.lastname : ''}
                                </div>

                                <div className="block w-full py-3">
                                    <span className="block uppercase font-black text-xs text-medicina-cafe-500">Forma de entrega:</span>
                                    { orden.client &&
                                        <Entrega direccion={orden.client.client.address} ciudad={orden.client.client.city} region={orden.client.client.region} />
                                    }
                                </div>

                                <div className="block w-full py-3">
                                    <span className="block uppercase font-black text-xs text-medicina-cafe-500">Información de contacto:</span>
                                    {orden.client ? orden.client.client.phone : 'SIN INFORMACIÓN'} <br />
                                    {orden.client ? orden.client.client.email : 'SIN INFORMACIÓN'}
                                </div>

                                <div className="block w-full py-3">
                                    <span className="block uppercase font-black text-xs text-medicina-cafe-500 mb-2">Estado de la compra:</span>
                                    <span className="py-1 px-3 rounded-full bg-green-600 text-white text-xs font-black uppercase"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


            {orden && orden.error &&
                <div className='py-3'>
                    <div className="lg:w-9/12 w-11/12 mx-auto">
                        <div className="flex-grow-0">
                            Not Found
                        </div>
                    </div>
                </div>
            }


        </MainLayout >
    )
}

export default isLoadingHOC(PaymentStatus, '🤖 : Cargando ordend de compra')