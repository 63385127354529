import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function CarouselComponent({link, slides, centerMode = false, dots = false, arrows = false, autoPlay = false }) {

    console.log('slides', slides)
    const settings = {
        infinite: true,
        centerPadding: "100px",
        slidesToShow: 1,
        autoplay: true,
        speed: 1400,
        autoplaySpeed: 6000,
        cssEase: "linear",
        dots: true,
        arrows: arrows,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    centerMode: false
                }
            },
        ]
    }

    const handleClick = (link) => window.location.href=link;

    return (
        <section className="w-full mb-8" role="banner">
            <Slider {...settings} >
                {slides && slides.map(slide => {
                    return (
                        <div key={slide.id}>
                            <div className="p-0 lg:p-2 my-0 lg:my-6">
                                <img src={'https://cdn.lamedicina.cl/attachments%2F2023%2Flarge%2F'+slide.attachment?.name} alt={slide.title} className="cursor-pointer w-full md:rounded-lg shadow" onClick={ () => handleClick(slide.url)} />
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </section>
    )
}
