import { ChatAlt2Icon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
export default function NavigationComponent() {
    
    return (
        <nav className="hidden lg:flex bg-white uppercase text-sm justify-between shadow-xl focus:sr-only">
            
            <ul className="flex self-center m-auto h-full items-center text-medicina-verde-500 divide-x py-2">
                <li><Link className="font-bold px-3 xl:px-6 hover:text-medicina-verde-400 hover:underline" title="Pagina Principal" to="/">Inicio</Link></li>
                <li><Link className="px-3 xl:px-6 hover:text-medicina-verde-400 hover:underline" title="Marcas y Bancos" to="/marcas">Marcas y Bancos</Link></li>
                {/* <li><Link className="px-3 xl:px-6 hover:text-medicina-verde-400 hover:underline" title="Quiénes Somos" to="/quienes-somos">Quiénes Somos</Link></li> */}
                <li><Link className="px-3 xl:px-6 hover:text-medicina-verde-400 hover:underline" title="Sucursales y horarios" to="/tiendas">Sucursales y Horarios</Link></li>
                <li><Link className="px-3 xl:px-6 hover:text-medicina-verde-400 hover:underline" title="Contacto" to="/contacto">Contactos</Link></li>
                <li><a href="https://blog.lamedicina.cl" className="px-3 xl:px-6 hover:text-medicina-verde-400 hover:underline" title="Noticias" to="/blog-y-noticias">Blog y Noticias</a></li>
                <li>
                    <a target="_blank" rel="noreferrer" className="px-3 font-semibold lg:px-6 hover:text-medicina-verde-400 hover:underline" title="Noticias" href="https://api.whatsapp.com/send?phone=56989550128&text=Conversaci%C3%B3n%20abierta%20desde%20Link">
                        Conversemos en WhatsApp! <ChatAlt2Icon className="w-5 h-5 inline-block" />
                    </a>
                </li>
            </ul>
        </nav>
    )
}
