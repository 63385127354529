import { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import SkeletonText from '../skeletonText/SkeletonText'

export default function PriceComponent({ price, prevPrice, productId = false }) {

    const [priceValue, setPriceValue] = useState(false)

    // si no hay precio, buscar con el id del producto...
    useEffect(() => {
        if (productId !== false && !price && priceValue === false) {
            const url = process.env.REACT_APP_API + "/price/" + productId
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    setPriceValue( data.message ? "no-price":data.data[0].price)
                   // console.log("price data",data)
                })
        }
    }, [priceValue, price, productId])

    return (
        <span>
            { prevPrice && price && prevPrice > price && <span className="text-medicina-cafe-500 text-sm flex w-full items-center justify-center font-light italic ">Antes: <NumberFormat value={prevPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false} prefix={'$'} /></span> }
            { price === false && priceValue === false && <SkeletonText /> }
            { price !== false && priceValue === false && <NumberFormat value={price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false} prefix={'$'} /> }
            { priceValue === "no-price" &&  price === false && <span>Precio no disponible</span>}
            { price === false && priceValue !== false && <NumberFormat value={priceValue} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false} prefix={'$'} /> }
            { priceValue === undefined && <SkeletonText />}
        </span >
    )
}
