import React, { useEffect, useState } from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import ProductSkeleton from '../../components/productSkeleton/ProductSkeleton'
import ProductCard from '../CategoryPage/ProductCard'
import FilterComponent from '../../components/FilterComponent/FilterComponent'

import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid'


export default function SearchPage() {

    const [hits, setHits] = useState([])
    const [page, setPage] = useState(1)
    const [showResults, setShowResults] = useState(false)

    useEffect(() => {
        setShowResults(false)
        const urlParams = new URLSearchParams(window.location.search);
        const query = urlParams.get('query');
        if (query) {
            const url = `${process.env.REACT_APP_API}/producto/buscar?query=${query}&page=${page}`
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    setHits(data)
                    setShowResults(true)
                })
        }
    }, [page])

    return (
        <MainLayout>
            <div className="w-full min-h-screen flex md:space-x-4 ">
                <div className="bg-gray-300 w-1/6 flex-grow-0">
                    <div className="sticky top-24">
                        <FilterComponent title="Marcas">sadasd</FilterComponent>
                        <FilterComponent title="Categorias">asdasd</FilterComponent>
                        <FilterComponent title="Etiquetas">asdasd</FilterComponent>
                    </div>
                </div>

                <div className="flex-grow w-full">

                    {showResults &&
                        <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 p-4 border-green-300 border-dashed">
                            {hits.hits &&
                                hits.hits.map(product => {
                                    return (
                                        // <ProductCard key={'search-hit-' + product.id + '-' + product.bsale_id} brand={product.__parentBrand} productSlug={product.slug} productName={product.name} imagen={product.imagen} productId={product.id} bsaleId={product.bsale_id} />
                                        <ProductCard 
                                            key={'product-card-' + product.id} 
                                            attachment={product.attachment} 
                                            productSlug={product.slug} 
                                            productId={product.id} 
                                            productName={product.name} 
                                            brand={product.brand} 
                                            bsaleId={product.bsale_id} />
                                    )
                                })
                            }
                        </div>
                    }


                    {!showResults &&
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 p-4">
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                            <ProductSkeleton />
                        </div>
                    }

                    <div className="w-full block p-4">

                        <div className="w-full flex justify-center items-center space-x-2 py-3">
                            <button className="rounded p-4  bg-white shadow" onClick={() => page > 1 ? setPage(page - 1) : ''}><ChevronLeftIcon className="w-6" /></button>
                            <button disabled className="rounded p-4 shadow-inner bg-gray-300 text-gray-600 font-black">{page ? page : "..."}</button>
                            <span className="px-2">de {hits.nbHits ? Math.floor(hits.nbHits / 20) === 0 ? 1 : Math.floor(hits.nbHits / 20) : "..."}</span>
                            <button className="outline-none rounded p-4 bg-white shadow" onClick={() => page < Math.floor(hits.nbHits / 20) ? setPage(page + 1) : ''}>Siguiente <ChevronRightIcon className="w-6 inline-block" /></button>
                        </div>

                    </div>
                </div>
            </div>


        </MainLayout>
    )
}
