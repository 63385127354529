import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'
import BrandWidgetComponent from '../../components/BrandWidgetComponent/BrandWidgetComponent'

export default function BrandsPage() {
    return (
        <MainLayout>
            <div className="min-h-screen w-11/12 mx-auto pt-10">
                <div className="w-full text-left mb-6 ">
                    <div className="pl-2">
                        <h1 className="text-4xl text-medicina-cafe-500 font-black leading-none medicina-font">Marcas y Bancos</h1>
                        <h6 className="text-xl font-light italic text-medicina-verde-500">Todos los productos de tus marcas favoritas.</h6>
                    </div>
                </div>
                <BrandWidgetComponent />
            </div>
        </MainLayout>
    )
}
