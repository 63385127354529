import { Highlight } from "./Highlight";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { RefreshIcon } from "@heroicons/react/solid";

export default function Hit({ hit, mobile = false }) {
  const [priceValue, setPriceValue] = useState(false);
  const handleClick = () => {
    window.location.href = `/producto/${hit.product_slug}-${hit.product_id}`;
  };

  useEffect(() => {
    if (!hit.price) {
      const url = process.env.REACT_APP_API + "/price/" + hit.product_id;
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setPriceValue(data.message ? "no-price" : data.data[0].price);
        });
    }
  }, [hit.price, hit.product_id]);

  return (
    <div
      className="bg-white w-full flex flex-wrap items-center border-b space-x-7 py-2 px-4 text-sm hover:bg-gray-200 cursor-pointer transition-all"
      onClick={handleClick}
    >
      {mobile && (
        <div className="flex-grow-0 ">
          <img
            className="w-12 h-12"
            src={
              hit.image_url ??
              "https://cdn.lamedicina.cl/static/img/noimage.png"
            }
            alt={hit.name}
          />
        </div>
      )}
    
      <div className="flex-grow truncate">
        <span className="w-full block uppercase font-thin text-medicina-verde-700">
          <Highlight attribute="brand" hit={hit} />
        </span>
        <span className="truncate">
        <Highlight attribute="name" hit={hit} />
        </span>
      </div>
      {hit.price && (
        <div>
          $
          <NumberFormat
            value={hit.price}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
          />
        </div>
      )}
      {!hit.price && !priceValue && (
        <div>
          <RefreshIcon className="w-4 h-4 animate-spin text-gray-300" />
        </div>
      )}
      {!hit.price && priceValue === "no-price" && (
        <div>Precio no disponible</div>
      )}
      {!hit.price && priceValue !== false && (
        <div className="flex-shrink-0">
          $
          <NumberFormat
            value={priceValue}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
          />{" "}
        </div>
      )}
    </div>
  );
}
