import React, { useState } from 'react'
import { Transition } from '@headlessui/react'
import LoaderComponent from '../components/LoaderComponent/LoaderComponent'

export const isLoadingHOC = (WrapperComponent, loadingMessage) => {
    
    function HOC(props) {
        const [isLoading, setLoading] = useState(false)
        const setLoadingState = isCOmponentLoading => setLoading(isCOmponentLoading)

        return (
            <>
                <Transition
                    show={isLoading}
                    enter="transition-opacity duration-200 z-50"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <LoaderComponent loadingMessage={loadingMessage} />
                </Transition>
                <WrapperComponent {...props} setLoading={setLoadingState} />
            </>
        )
    }
    return HOC;
}

export default isLoadingHOC