import React from 'react'
import { XCircleIcon } from '@heroicons/react/outline'
import CategoryReveal from './CategoryReveal'

export default function CategoryMenu({setOpen}) {

    return (
        <>
            <div className="w-full bg-white rounded shadow-2xl flex flex-col">
                <div className="w-full text-left flex justify-between flex-grow-0">
                    
                    <div className="px-4 pt-4">
                        <h1 className="text-4xl text-medicina-cafe-500 font-black leading-none medicina-font">Categorías</h1>
                    </div>

                    <div className="p-4 cursor-pointer" onClick={() => setOpen(false) }>
                        <XCircleIcon className="w-8 h-8 text-medicina-verde-500 hover:text-medicina-verde-900 transition-colors" />
                    </div>
                </div>
                
                <div className="overflow-y flex-grow block">
                    <CategoryReveal />
                </div>

            </div>
        </>
    )
}
