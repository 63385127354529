import React from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

export default function FilterComponent({ children, title }) {

    return (
        <div className="min-h-full w-full bg-gray-300 ">

            <Disclosure >
                {({ open }) => (
                    <>
                        <Disclosure.Button className="block w-full ">
                            <div className={`w-full flex items-center justify-between px-2 py-2 border-b border-gray-400 bg-gray-500 ${open ? 'bg-medicina-cafe-500 text-medicina-verde-50' : 'text-medicina-cafe-50'}`}>
                                <h1>{title ?? 'Filtros'}</h1>
                                <ChevronDownIcon className="w-6 h-6" />
                            </div>
                        </Disclosure.Button>

                        <Transition
                            enter="transition duration-100 ease-out origin-top"
                            enterFrom="transform scale-y-0 opacity-0"
                            enterTo="transform scale-y-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-y-100 opacity-100"
                            leaveTo="transform scale-y-70 opacity-0 origin-top"
                        >
                            <Disclosure.Panel className="text-gray-500 bg-gray-400 ">
                                <div className="overflow-y-auto max-h-52 shadow-inner uppercase">
                                    {children}
                                </div>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>
        </div>
    )
}
