import {
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";
import { useEffect } from "react";
import { usePagination } from "react-instantsearch-hooks";
import { cx } from "./cx";
import { isModifierClick } from "./isModifierClick";

export function Pagination(props) {

  useEffect(() => {

    setTimeout(() => {
      //window.location.reload();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 500)

  }, [])

  const {
    refine,
    createURL,
    pages,
    currentRefinement,
    isFirstPage,
    isLastPage,
    nbPages,
    canRefine,
  } = usePagination(props);


  return (
    <div
      className={cx(
        "flex text-sm md:tex justify-center rounded flex-col mb-3 text-medicina-cafe-500",
        canRefine === false && "ais-Pagination--noRefinement",
        props.className
      )}
    >
      <ul className="flex justify-center">
        <PaginationItem
          aria-label="First"
          value={0}
          isDisabled={isFirstPage}
          createURL={createURL}
          refine={refine}
          className={cx(
            "ais-Pagination-item rounded-tl rounded-bl",
            "ais-Pagination-item--firstPage"
          )}
        >
          <ChevronDoubleRightIcon className="w-5 h-5 transform -rotate-180" />
        </PaginationItem>

        {pages.map((page) => (
          <PaginationItem
            key={page}
            aria-label={String(page)}
            value={page}
            isDisabled={false}
            createURL={createURL}
            refine={refine}
            className={cx(
              "ais-Pagination-item",
              page === currentRefinement &&
              "ais-Pagination-item--selected font-black text-medicina-verde-500"
            )}
          >
            {page + 1}
          </PaginationItem>
        ))}

        <PaginationItem
          aria-label="Last"
          value={nbPages - 1}
          isDisabled={isLastPage}
          createURL={createURL}
          refine={refine}
          className={cx(
            "ais-Pagination-item",
            "ais-Pagination-item--lastPage rounded-tr rounded-br"
          )}
        >
          <ChevronDoubleRightIcon className="w-5 h-5" />
        </PaginationItem>
      </ul>
    </div>
  );
}

function PaginationItem(props) {

  const handleClick = () => {
    setTimeout(() => {
      window.location.reload();
    }, 500)
    /*    window.scrollTo({
         top: 0,
         left: 0,
         behavior: "smooth",
       }); */
  };
  const { isDisabled, className, href, value, createURL, refine, ...rest } =
    props;

  if (isDisabled) {
    return (
      <li
        className={cx(
          className,
          "bg-white py-2 px-2 text-gray-300 ais-Pagination-item--disabled flex items-center shadow"
        )}
      >
        <span className="ais-Pagination-link" {...rest} />
      </li>
    );
  }

  return (
    <li className={className + " bg-white py-2 px-2 flex items-center shadow"} >
      <a
        className="ais-Pagination-link hover:text-medicina-verde-600"
        href={createURL(value)}
        title={`Go to page ${value + 1}`}
        onClick={(event) => {
          if (isModifierClick(event)) {
            return;
          }
          event.preventDefault();
          handleClick();
          refine(value);
        }}
        {...rest}
      >{value + 1}</a>
    </li>
  );
}
