import algoliasearch from "algoliasearch/lite";
import { useEffect, useState } from "react";

export default function AlgoliaSearch() {

  const [results] = useState([]);

  useEffect(() => {

    if (results.length === 0) {

      const searchClient = algoliasearch(
        "1AW7QK3708",
        "0078f54885ff4a4a96ed89a721d1d562"
      )
      const index = searchClient.initIndex("lamedicina_ecommerce_PROD_products")
      
      index.search("pipa")
      .then(function(response) {
        console.log(response)
      })
    
    }

  }, [results])






  return (
    <div className="max-w-7xl mx-auto">
      <h1>Buscador la medicina</h1>
    </div>
  );
}
