import ProductCard from '../CategoryPage/ProductCard'
import SalesPaginator from './SalesPaginator';
import { useState, useEffect } from 'react'
import algoliasearch from "algoliasearch";

export default function SalesComponent({ origen = false }) {

    const [productData, setProductData] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)

    // An effect that gets the current page from the URL and sets it as the current page
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const page = urlParams.get('page')
        if (page) {
            setCurrentPage(parseInt(page))
        }
    }, [])

    useEffect(() => {
        const d = new Date()
        let filter = "insale:true"
        // let filter = "insale:true AND sale_timestamp > ${Math.floor(d.setDate(d.getDate() - 30) / 1000)}"

        if (origen) {
            switch (origen) {
                case 'ofertas':
                    // filter = `insale:true AND sale_timestamp > ${Math.floor(d.setDate(d.getDate() - 30) / 1000)}`
                    filter = `insale:true`
                    break;
                case 'promos':
                    filter = `isPack:true`
                    break;
                default:
                    filter = `insale:true`
                    break;
            }
        }

        console.log(filter)
        const searchClient = algoliasearch(
            "1AW7QK3708",
            "0078f54885ff4a4a96ed89a721d1d562"
        )
        const index = searchClient.initIndex("ofertas")

        index.search("", {
            filters: `${filter}`,
            page: currentPage ?? 0,
        })
            .then(function (response) {
                console.log(response)
                setProductData(response)
            })
            .catch(function (error) {
                console.log(error)
            })
    }, [currentPage])


    return (
        <>
            {productData &&
                <>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                        {productData.hits.map(product => <ProductCard key={'product-card-' + product.product_id}
                            attachment={product.attachment}
                            productSlug={product.product_slug}
                            imageUrl={product.image_url}
                            productId={product.product_id}
                            productName={product.name}
                            brand={product.brand ?? 'Genérico'}
                            bsaleId={product.bsale_id}
                            price={product.price ?? false}
                            prevPrice={product.prev_price ?? false}
                            insale={product.insale ?? false}
                            isBundle={product.isPack ?? true}
                            pack={product.pack ?? false}
                        />)}
                    </div>


                    <SalesPaginator currentPage={currentPage}
                        realHits={productData.hits.length}
                        totalPages={productData.nbPages}
                        resultsPerPage={productData.hitsPerPage}
                        setCurrentPage={setCurrentPage} totalHits={productData.nbHits} />
                </>
            }
        </>
    )
}
