import CarouselComponent from '../../components/CarouselComponent/CarouselComponent'
import { useEffect, useState } from 'react';

export default function Hero() {

    const [slides2, setSlides] = useState(false);

    useEffect(() => {
        const url = 'https://api-lamedicina-cl-dxy32.ondigitalocean.app/api/v2/banners';
        if (!slides2) {
            return fetch(url)
                .then(res => res.json())
                .then(data => {
                    console.log(data.data)
                    // https://cdn.lamedicina.cl/attachments%2F2023%2Flarge%2F0365f2c72e25d571b87e28e2e75d5e40.png
                    setSlides(data.data)
                })
        }
    }, [slides2, setSlides])

    const slides = [
        {
            id: "1",
            bg_image: "https://cdn.lamedicina.cl/banners/BANNER_GALERIA.png",
            link: "/"
        },
        {
            id: "2",
            bg_image: "https://cdn.lamedicina.cl/banners/BANNER_SEMILLAS.jpg",
            link: "/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level1%5D%5B0%5D=cultivo%20>%20semillas&lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Binsale%5D%5B0%5D=true"
        },
        {
            id: "22",
            bg_image: "https://cdn.lamedicina.cl/banners/strain.png",
            link: "https://lamedicina.cl/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bbrand%5D%5B0%5D=STRAIN%20MACHINE"
        },
        {
            id: "23",
            bg_image: "https://cdn.lamedicina.cl/banners/bienestar.png",
            link: "https://lamedicina.cl/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bhirearchical_categories.level0%5D%5B0%5D=bienestar"
        },
        {
            'id': '10',
            'bg_image': 'https://cdn.lamedicina.cl/banners/BANNER_MB.png',
            'link': 'https://lamedicina.cl/producto/magical-butter-3138'
        },
        {
            'id': '8',
            'bg_image': 'https://cdn.lamedicina.cl/banners/BANNER_LEC_LED.png',
            'link': 'https://lamedicina.cl/categorias/productos?lamedicina_ecommerce_PROD_products%5BrefinementList%5D%5Bbrand%5D%5B0%5D=THE%20KING%20OF%20GREEN'
        },
        {
            'id' : '7',
            'bg_image' : 'https://cdn.lamedicina.cl/banners/BANNER_HORARIO.png',
            'link' : '/'
        },
        {
            id: "5",
            bg_image: "https://cdn.lamedicina.cl/banners/BANNER_DESCUENTOS.jpg",
            link: "/ofertas"
        },
        {
            id: "12",
            bg_image: "https://cdn.lamedicina.cl/promo/banner.png",
            link: "/promos"
        },
    ]

    return <CarouselComponent slides={slides2} key="hero-slider" dots={true} autoPlay={true} centerMode={true} arrows={true} />

}
