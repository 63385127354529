import { useRefinementList } from 'react-instantsearch-hooks';
import {cx} from './cx.js';

export function RefinementList(props) {
  
  const { items, refine } = useRefinementList(props);

  const setLabel = (item) => {
    if (item.label.split(">").length > 1) {
      return item.label.split(">")[item.label.split(">").length -1 ]
    }

    if (item.label == "false") return "No"
    if (item.label == "true") return "Si"

    return item.label
  }

  
  return (
    <div className="ais-RefinementList">
      <ul className="ais-RefinementList-list">
        {items.map((item) => (
          <li
            key={item.value}
            className={cx(
              'ais-RefinementList-item',
              item.isRefined && 'ais-RefinementList-item--selected'
            )}
          >
            <label className="ais-RefinementList-label text-xs">
              <input
                className="ais-RefinementList-checkbox mr-2"
                type="checkbox"
                value={item.value}
                checked={item.isRefined}
                onChange={() => refine(item.value)} // aqui falta cambiar la url para iyectar el child
              />
              <span className="uppercase mr-2">{setLabel(item)}</span>
              <span className="text-gray-400">({item.count})</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}