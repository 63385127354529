import { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'

export default function SiblingsComponent({ parentId, activeId }) {

    const [siblings, setSiblings] = useState([])

    useEffect(() => {
        const url = process.env.REACT_APP_API + '/siblings/' + parentId
        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setSiblings(data.data)
            })
    }, [parentId])

    return (
        <div>
            <span className="block text-sm mb-4 text-gray-400 uppercase font-semibold mt-14">Opciones de compra</span>
            <div className="flex flex-wrap flex-row justify-between space-4">
                {siblings &&
                    siblings.map(option => {

                        if (option.id !== activeId) {
                            return (
                                <a href={"/producto/" + option.slug + "-" + option.id} key={'opcion-' + option.id}
                                    className={'hover:bg-medicina-verde-500 hover:text-white transition-colors flex-grow p-2 rounded shadow-sm m-2 text-center text-medicina-cafe-300 bg-medicina-cafe-100'}>
                                    <h2 className="text-sm font-light">{option.name}</h2>
                                    <h2 className="text-md font-bold">
                                        $<NumberFormat value={option.price[0] && option.price[0].price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                                    </h2>
                                </a>)
                        } else {

                            return (
                                <div key={'opcion-' + option.id}
                                    className={'transition-colors flex-grow p-2 rounded shadow-sm m-2 text-center ring-4 ring-medicina-verde-400 bg-medicina-verde-500 text-white'} href={'/producto/' + option.slug + '-' + option.id}>
                                    <h2 className="text-sm font-light">{option.name}</h2>
                                    <h2 className="text-2xl font-bold text-medicina-verde-100">
                                        <NumberFormat value={option.price[0] && option.price[0].price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                                    </h2>
                                </div>)
                        }
                    })
                }
            </div>
        </div>
    )
}
