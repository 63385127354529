import React from 'react'

export default function Logo() {
    return (
        <a href="/">
            <img
                className="h-16 cursor-pointer transform transition-transform hover:-rotate-6 hover:scale-110 px-4"
                src="https://cdn.lamedicina.cl/static/img/medicina.svg"
                alt="La Medicina Tienda de Cultivo - Tienda En Linea"
            />
        </a>
    )
}
