import { Highlight } from "./Highlight";
import ProductCard from "../CategoryPage/ProductCard";

export default function Hit({ hit }) {
  return (
    <ProductCard
      key={'product-card-' + hit.product_id}
      attachment={hit.attachment}
      productSlug={hit.product_slug}
      imageUrl={hit.image_url}
      productId={hit.product_id}
      brand={hit.brand ?? 'Genérico'}
      bsaleId={hit.bsale_id}
      price={hit.price ?? false}
      prevPrice={hit.prev_price ?? false}
      insale={hit.insale ?? false}
      isBundle={hit.isPack ?? false}
      pack={hit.pack ?? false}
      productName={<Highlight attribute="name" hit={hit} />}
    />
  );
}
