import {
  getHighlightedParts,
  getPropertyByPath,
} from "instantsearch.js/es/lib/utils";

function HighlightPart({
  children,
  highlightedTagName,
  isHighlighted,
  nonHighlightedTagName,
}) {
  const TagName = isHighlighted ? highlightedTagName : nonHighlightedTagName;

  return (
    <TagName
      className={
        isHighlighted
          ? "ais-Highlight--highlighted"
          : "ais-Highlight--nonHighlighted"
      }
    >
      {children}
    </TagName>
  );
}

export function Highlight({
  hit,
  attribute,
  highlightedTagName = "mark",
  nonHighlightedTagName = "span",
  ...rest
}) {
  const { value: attributeValue = "" } =
    getPropertyByPath(hit._highlightResult, attribute) || {};
  const parts = getHighlightedParts(attributeValue);

  return (
    <span {...rest} className="ais-Highlight">
      {parts.map((part, partIndex) => {
        return (
          <HighlightPart
            key={partIndex}
            highlightedTagName={highlightedTagName}
            nonHighlightedTagName={nonHighlightedTagName}
            isHighlighted={part.isHighlighted}
          >
            {part.value}
          </HighlightPart>
        );
      })}
    </span>
  );
}
