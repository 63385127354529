import { useContext } from 'react'
import CartContext from '../../shared/ShoppingCart/CartContext'

export default function ConfirmClientInfo({ idOrden, setClientOrder, clientOrder }) {

    const cartContext = useContext(CartContext)
    
    const handleClick = () => {

        const formData = new FormData()

        formData.append('buy_order', idOrden)
        formData.append('name', cartContext.state.clientInfo.name)
        formData.append('lastname', cartContext.state.clientInfo.lastname)
        formData.append('phone', cartContext.state.clientInfo.phone)
        formData.append('email', cartContext.state.clientInfo.email)
        formData.append('rut', cartContext.state.clientInfo.rut)

        if (cartContext.state.clientInfo.delivery) {
            formData.append('region', cartContext.state.clientInfo.region)
            formData.append('city', cartContext.state.clientInfo.ciudad)
            formData.append('address', cartContext.state.clientInfo.address)
        }

        fetch(`${process.env.REACT_APP_API}/clients`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(data => setClientOrder(data))
            .catch(err => console.log(err))

    }


    return (
        <div className="block mb-4">
            {clientOrder === false && <button type="button" onClick={handleClick} className="w-full bg-medicina-verde-500 text-white py-2 px-4 rounded">Confirmo que mis datos son correctos.</button>}
            {clientOrder !== false && <button type="button" disabled className="w-full bg-gray-500 text-gray-300 py-2 px-4 rounded">Confirmo que mis datos son correctos.</button>}
        </div>
    )
}
