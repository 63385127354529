import React from "react";
import MainLayout from "../../components/Layout/MainLayout";

export default function TermsPage() {
  return (
    <MainLayout>
      <div className="w-9/12 mx-auto mt-10 bg-white p-5 rounded shadow pb-10">
        <h1 className="medicina-font text-4xl text-medicina-verde-500 pb-12">
          Términos y condiciones de uso
        </h1>
        <h1 className="medicina-font text-3xl hover:underline cursor-pointer text-medicina-verde-500 py-4">
          Política de envíos y entrega
        </h1>
        <p className="pb-10">
          Realizamos envíos a todas las regiones del país. Para el correcto
          despacho del producto es necesario que revises tus datos como nombre,
          dirección, teléfono de contacto ya que esta información es fundamental
          para que nosotros gestionemos la correcta y oportuna entrega.
          Te recomendamos si este es el caso nos escribas solicitando tu envío a
          la sucursal que más te acomode, escribiendo en la casilla “nota del
          pedido”, la agencia y sucursal elegida. De esta manera, podrás
          programar el retiro cuando más te acomode.
          <p className="my-4">
          Tiempo aproximado de envío.
          </p>
          Punta Arenas: 24 Hrs hábiles. <br />
          Puerto Natales: 48 hrs. <br />
          Hábiles Resto del pais (Chile Continental): 2 a 3 para envío. dependerá del medio
          escogido.
        </p>
        <h1 className="medicina-font text-3xl hover:underline cursor-pointer text-medicina-verde-500 py-4">
          Opciones de Envío
        </h1>
        <p>
          <span className="font-bold block">Despacho de productos</span>
          Las condiciones de despacho y entrega de los productos adquiridos
          podrán ser escogidas de entre las opciones ofrecidas en La
          Medicina.cl. El tiempo aproximado del envío se indica al momento de
          seleccionar una opción en el carro de compra. Sin embargo, dicho
          tiempo estará sujeto a la contingencia y puede variar. En caso de que
          el tiempo de entrega exceda lo estipulado al momento de comprar, debes
          enviar un correo a lamedicintiendadecultivo@gmail.com, incluyendo tu
          código de compra. La persona encargada revisará la causa de la demora
          y se pondrá en contacto contigo.
        </p>
        <span className="font-bold block">Retiro en tienda </span>
        <p>
          El retiro en tienda se encuentra disponible en las sucursales de La
          Medicina ubicadas en Punta Arenas. Para ello, debes seleccionar dicha
          opción en tu carro de compras. Una vez que el producto esté listo para
          retirar te informaremos vía correo electrónico, indicando el plazo
          disponible para hacerlo. Los comprobantes de esta gestión quedarán
          disponibles durante 30 días. Si el producto no fuera retirado dentro
          del plazo especificado, la transacción quedará sin efecto, haciéndose
          la devolución de la cantidad pagada, dentro de los plazos indicados.
        </p>
        <span className="font-bold block">Registro</span>
        <p>
          No es obligación registrarse como usuario en la web de La Medicina
          para realizar una compra. Sin embargo, al hacerlo tendrás una
          experiencia de compra más segura y confidencial. Además, te facilitará
          la tarea de hacer seguimiento a tu pedido. También cabe destacar que
          quienes estén dentro de la comunidad online de Lamedicina.cl estarán
          constantemente participando de concursos y disfrutando de novedades y
          descuentos especiales. Para registrarte en el sitio, deberás llenar el
          formulario y crear una clave secreta. Toda la información que ingreses
          será completamente confidencial y sólo se utilizará para crear la
          boleta y los envíos de tus compras. ¨podrás modificar tu contraseña
          tantas veces como quieras.
        </p>
        <h1 className="medicina-font text-3xl hover:underline cursor-pointer text-medicina-verde-500 py-4">
          Garantías
        </h1>
        <span className="font-bold block">Garantía Legal</span>
        <p>
          Sabemos sobre la calidad de nuestros productos, pero si llegase a
          existir algún daño en el envío, no dudes en avisarnos y responderemos
          prontamente para que tengas tu producto en perfecto estado. Te
          sugerimos utilizar de forma correcta tus productos, ya que en caso de
          daños provocados por un mal uso no podrás hacer efectiva tu garantía.
          En caso de que el producto no cuente con las características técnicas
          informadas en La Medicina; si estuviera dañado o incompleto, podrá ser
          cambiado. Si presenta fallas o defectos dentro de los 3 meses
          siguientes a la fecha en que fue recibido, puede optarse entre su
          reparación gratuita, o previa restitución, su cambio o la devolución
          de la cantidad pagada, siempre que el producto no esté deteriorado por
          manipulación tuya. Puede entregarse directamente en un servicio
          técnico autorizado por el fabricante o en cualquier sucursal de La
          Medicina. Para hacer efectiva la garantía debes presentar: La boleta
          de compra del producto y todas sus piezas en envase original.
        </p>

        <h1 className="medicina-font text-3xl hover:underline cursor-pointer text-medicina-verde-500 py-4">
          Derecho a retracto
        </h1>
        <p>
          Solo se aceptarán productos que estén sin abrir, siendo él envió por
          cargo a la persona que desee realizar el retracto de compra. Este debe
          realizarse antes de los 10 días desde que se realizó la compra.
        </p>

        <h1 className="medicina-font text-3xl hover:underline cursor-pointer text-medicina-verde-500 py-4">
          Generalidades
        </h1>
        <p>
          Este documento regula los términos y condiciones bajo los cuales Ud.
          tiene derecho a acceder y usar los servicios del sitio Web
          www.lamedicina.cl y de cualquier información, texto, video u otro
          material comunicado en el sitio web. En este sitio Web podrá usar, sin
          costo, el software y las aplicaciones para equipos móviles que le
          permitan navegar, visitar, comparar y si lo desea, adquirir los bienes
          o servicios que se exhiben aquí. Le recomendamos que lea detenidamente
          estas Condiciones e imprima o guarde una copia de las mismas en la
          unidad de disco local para su información. Estos Términos y
          Condiciones serán aplicados y se entenderán incorporados en cada uno
          de los contratos que celebre con La Medicina por medio de este sitio
          web. El uso de este sitio web, la aplicación de estos Términos y
          Condiciones, los actos que ejecute y los contratos que celebre por
          medio de este sitio web, se encuentran sujetos y sometidos a la Ley
          19.496 de Protección de los derechos de los consumidores. La Medicina,
          por lo tanto, aplicará estrictamente todos los beneficios, garantías y
          derechos reconocidos en favor de los consumidores en la ley 19.496.
          Aviso Legal La Medicina.cl es una tienda online dedicada a la venta de
          parafernalia, artículos agrícolas y electrónicos, así como semillas de
          cannabis creadas por los mejores bancos y cultivadores del mundo. Se
          requiere ser mayor de 18 años para ingresar y comprar en nuestro sitio
          web. Agricola La Medicina no se hará responsable por el uso indebido
          que puedan hacer los compradores que utilicen las semillas para
          cultivo sin contar con la autorización de las autoridades competentes.
          Expresamente, puntualizamos que todo aquel que compre semillas de
          cannabis es responsable de sus propios actos.
        </p>
      </div>
    </MainLayout>
  );
}
