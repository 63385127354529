import { useState, useContext, useEffect } from 'react'
import { XIcon, CheckIcon } from '@heroicons/react/solid'
import { Switch, Transition } from '@headlessui/react'
import LocationComponent from '../../components/LocationComponent/LocationComponent'
import CartContext from '../../shared/ShoppingCart/CartContext'

export default function Delivery() {

    const [enabled, setEnabled] = useState(false)
    const [city, setCity] = useState(false)
    const [prev, setPrev] = useState(true)
    const cartContext = useContext(CartContext)

    useEffect(() => {
        if (enabled) {
            cartContext.updateClientInfo({ delivery: true })
        } else {
            cartContext.updateClientInfo({ delivery: false })
        }
    }, [enabled])

    const childSetCity = (city) => setCity(city)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <>
            <div className="w-full flex justify-between items-center p-4 shadow-inner bg-gray-300 rounded-xl mb-10 mt-10">
                <div className="text-lg text-medicina-verde-700">
                    Despacho a domicilio?
                    <p className="text-sm text-medicina-cafe-700"> 
                        { city !== 'Punta Arenas' && <span>Nos contactaremos contigo a la brevedad para poder coordinar el método de envío más favorable". 
                        ¡Es de suma importancia que nos dejes con claridad tus datos para poder contactarnos contigo!</span> }

                        { city === 'Punta Arenas' && <span>La opción de despacho a domicilio dentro de la ciudad de Punta Arenas tiene un costo extra de $2000 que se reflejaran al momento del pago.
                            Podrás recibir tu pedido el mismo día siempre y cuando se haya efectuado la compra antes de las 12:00. De ser después, recibirás al día siguiente 
                            (Si la compra fue efectuada un día sábado después de las 12:00 recibirás el pedido el día lunes)</span>}
                    </p>
                </div>
                <Switch
                    onChange={() => setEnabled(!enabled)}
                    className={classNames(
                        enabled ? 'bg-medicina-verde-600' : 'bg-gray-400',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-medicina-verde-500'
                    )}
                >
                    <span className="sr-only">Use setting</span>
                    <span
                        className={classNames(
                            enabled ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                    >
                        <span
                            className={classNames(
                                enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                            )}
                            aria-hidden="true"
                        >
                            <XIcon className="h-3 w-3 text-gray-400" />
                        </span>
                        <span
                            className={classNames(
                                enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                            )}
                            aria-hidden="true"
                        >
                            <CheckIcon className="h-3 w-3 text-medicina-verde-600" />
                        </span>
                    </span>
                </Switch>


            </div>
            <Transition show={enabled}>
                <div className="block mb-4">
                    <LocationComponent setCity={childSetCity} />
                </div>
            </Transition>

        </>
    )
}
