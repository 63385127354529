import { useState, useContext, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/solid'
import CartContext from '../../shared/ShoppingCart/CartContext'
import CartTitle from './CartTitle'
import CartItemComponent from './CartItemComponent'
import NumberFormat from 'react-number-format'
import isLoadingHOC from '../../shared/isLoadingHOC'

export const CartComponent = ({ setLoading }) => {

    const [total, setTotal] = useState(0)
    const [ttotal, setTtotal] = useState(0)
    const [products, setProducts] = useState([])
    const cartContext = useContext(CartContext)

    const pagar = () => {
        // aca puedo validar desde el localstorage si es que tiene una orden de compra
        // ya creada, para eliminarla o actualizar la fila con el id....
        setLoading(true)
        const url = process.env.REACT_APP_API + '/buyOrder'
        fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(cartContext.state.cart) })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                window.localStorage.setItem('user_orders',  JSON.stringify({'order_id':data.orden_compra, 'sin_stock': data.sin_stock}))
                if (data.orden_compra) {
                    cartContext.toggleCart()
                    console.log(data)
                    window.location.href = '/pagar/orden/'+data.orden_compra
                }
            })
            // hay que capturar este error...
            .catch(err => console.error(err))
    }
    useEffect(() => {
                let queryString = ""
                cartContext.state.cart.map(item => queryString += item.productId + ',')
                const url = `${process.env.REACT_APP_API}/products/items/` + queryString
                if (queryString !== "") {
                    return fetch(url)
                        .then(res => res.json())
                        .then(data => setProducts(data.data))
                }
            }, [cartContext.state.cart])

    useEffect(() => {
                let total = 0
                console.log(products)
                if (products.length > 0 ) {
                    products.map(product => {
                        const _index = cartContext.state.cart.map(item => item.productId).indexOf(product.id)
                        const price = product.price ? product.price.price : product.price ? product.parent.price ?? 0 : 0
                        const unidades = cartContext.state.cart[_index] ? cartContext.state.cart[_index].quantity : 0 
                        total += parseInt(price) * parseInt(unidades)
                        return setTotal(total)
                    })
                }
            }, [products, ttotal, cartContext.state.cart])

    return (
            <Transition show={cartContext.state.showCart}
                enter="transition-opacity duration-200"
                enterFrom="opacity-0 -mr-screen"
                enterTo="opacity-100 -mr-0"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="z-20"
            >
                <div className="w-screen h-screen fixed top-0 left-0 z-40">
                    <div className="w-full h-full backdrop-filter backdrop-blur-lg bg-medicina-verde-900 bg-opacity-80  flex justify-end">

                        <div className="h-full w-20 flex justify-end items-start pt-3 pr-3">
                            <XCircleIcon className="cursor-pointer hover:text-medicina-verde-500 w-10 h-10 text-medicina-verde-200" onClick={() => cartContext.state.showCart ? cartContext.toggleCart() : false} />
                        </div>

                        <div className="w-full md:w-1/2 lg:w-5/12 bg-white h-full shadow-2xl">

                            <div className="h-full p-4 flex flex-col space-y-2">
                                <div>
                                    <CartTitle title="Carrito de Compras" de comprasclassName="flex-grow-0" total={cartContext.state.totalProductos} />
                                </div>

                                <div className="flex-grow overflow-y-auto divide-y pt-2">
                                    {products.length > 0 &&
                                        products.map( (item,i) => <CartItemComponent attachment={item.attachment} setTtotal={setTtotal} product={item} quantity={cartContext.state.cart} key={'item-en-carro-' + item.id} index={cartContext.state.cart.map( item=> item.productId).indexOf(item.id)} />)
                                    }
                                </div>

                                {total > 0 &&
                                    <div className="flex-grow-0 text-center bg-gray-300 rounded shadow-inner p-3 text-medicina-verde-500">
                                        <h1 className="text-xl ">Total:<span className="font-black ml-4">$<NumberFormat value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></span> <span className="text-sm">(iva incluido)</span></h1>
                                    </div>}

                                <div className="flex-grow-0">
                                    <button onClick={() => { pagar() }} className="w-full py-2 rounded medicina-font text-xl bg-medicina-cafe-400  transition-colors hover:bg-medicina-cafe-600 text-medicina-cafe-50">Pasar por caja</button>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>
            </Transition>

        )

    }

    export default isLoadingHOC(CartComponent, 'Comprobando stock y precio de productos...')